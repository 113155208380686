import { Flex, Heading } from "@adobe/react-spectrum";
import React from "react";
import adobeLogo from "../../images/adobe_log_red.png";
export default function LoginHeaderComponent() {
    return (
        <Flex alignItems={'center'} gap={'10px'} UNSAFE_className="headerWrapper loginHeader">
            <Flex width={'2rem'}>
                <img src={adobeLogo} style={{width:"2rem", height:"2rem"}} alt="Adobe Logo" />
            </Flex>
            <Flex>
                <Heading UNSAFE_className="loginHeaderTitle">Adobe Interaction Client</Heading>
            </Flex>
        </Flex>
    )
}