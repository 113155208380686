import { connectSession } from "./session";
import { CCP_STATES, LOG_PREFIX } from "../../constants/constants";
import { updateCallAndSend } from "./call";
import { CCP_STATE, STATE_AGENT, STATE_LOGIN } from "../constants";
import { changeAgentState } from "../api/agent";
import Emitter from "../../emitter";

const lp = LOG_PREFIX + "[handlers.agent.ts]: ";

function handleAgentRefresh(agent: any) {
  console.log(lp + "Agent Refreshed", agent);
  Emitter.emit(CCP_STATE, { status: CCP_STATES.CONNECTED });
}

// We will log in the browsers console any new agent status
function handleAgentStateChange(stateObj: {
  agent: any;
  newState: string;
  oldState: string;
}) {
  try {
    const stateType = (
      stateObj.agent.getState() ||
      stateObj.agent.getStatus() ||
      {}
    ).type;
    let { newState } = stateObj;
    if (
      (stateType === "system" || stateType === "System") &&
      newState !== "AfterCallWork"
    )
      return;

    const nextState = stateObj?.agent?.getNextState();
    if (nextState && nextState?.name !== newState) {
      if (
        (nextState?.type === "system" || nextState?.type === "System") &&
        nextState?.name !== "AfterCallWork"
      ) {
        // Ignore
      } else {
        console.log(
          lp + "Found next state changing new state to next State",
          newState,
          nextState
        );
        newState = nextState.name;
      }
    }
    Emitter.emit(STATE_AGENT, {
      id: newState,
      displayName: newState,
      stateModifiedDate: new Date().getTime(),
    });
  } catch (error) {
    console.log(
      `${lp} Error in changing agent state to UI from ${stateObj?.oldState} to ${stateObj?.newState}`,
      error
    );
    throw error;
  }
}

export function subscribeToAgentEvents(agent: any) {
  // Subscribing to Streams API Long Polling Agent events
  connectSession.agent = agent;
  var config = agent.getConfiguration();
  console.log(lp + "Agent Configuration", config);
  //TODO: Check if we need to emit this event
  Emitter.emit(STATE_LOGIN, {
    body: { ...config, agentLoginTime: new Date().toISOString() },
    success: true,
  });
  agent.onRefresh(handleAgentRefresh);
  agent.onStateChange(handleAgentStateChange);
  agent.onMuteToggle(
    function (obj: any) {
      const contact = agent.getContacts(connect.ContactType.VOICE)[0];
      if (obj.muted) {
        console.log(lp + "agent is muted successfully during contact", contact);
      } else {
        console.log(lp + "agent is unmuted during contact ", contact);
      }

      updateCallAndSend("MUTETOGGLE", contact, { muted: obj.muted });
    },
    function (error: any) {
      const c = agent.getContacts(connect.ContactType.VOICE);
      let contact = null;
      if (c.length > 0) {
        contact = c[0];
      }
      console.log(lp + "Error in mute handler", error, contact);
      throw error;
    }
  );
}

export function resetAfterCallWork() {
  const acwState = {
    state: "COMPLETE",
  };
  return acwState;
}
export function handleAfterCallWork(contact: any) {
  console.log(lp + "Agent is in After Call Work state", contact);
  const acwState = {
    state: "PENDING",
  };

  setTimeout(() => resetAfterCallWork, 5000);
  return acwState;
}

export function changeAgentStateToAvailable() {
  // const availableState = connectSession.agent.getAgentStates().filter(function (state: any) {
  //     return state.name.toLocaleLowerCase() === "Available".toLocaleLowerCase();
  // })[0];
  console.log(
    "Calling to set agent status to available if on Contact Reject or Rona",
    "Available"
  );
  if (connectSession.agent.getStatus().name === "Available") {
    console.log("No change: agent is already on Available state");
    return;
  }
  changeAgentState({ body: { agentState: "Available" } });
}
