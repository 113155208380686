import { CONTACT_EVENT_STATE_MAP, CONTACT_STATE_MAP, STATE_CHAT, STATE_WEBCHATMESSAGE, BG_TELEMETRY, CHANNEL_TYPES, LOG_PREFIX } from '../../constants/constants';
import { ChatMessage } from './../models/chat';
import { Chat } from './../models/chat';
import { formatAttributes } from '../../utils';
import { changeAgentStateToAvailable } from './agent';
import  { disconnectChatContact, clearCurrentChatContact } from '../api/chat';
import Emitter from '../../emitter';
import { API_MILO_CHAT_CHANNEL_TRANSACTION } from '../constants';
const lp = LOG_PREFIX + "[handlers.chat.ts]: ";
const defaultValue = {
    id: null,
    self: {},
    customer: {},
}

var chats: {[key: string]: Chat} = {};

function setChat(chatObj: Chat) {
    //Below ignore duplicate call ended events
    const existingChat = chats.hasOwnProperty(chatObj.id);
    if(chatObj.self?.state === CONTACT_STATE_MAP.ENDED && !existingChat) return;
    if(chatObj.self?.state === CONTACT_STATE_MAP.ENDED) {
        if(chats.hasOwnProperty(chatObj.id)) {
            delete chats[chatObj.id];
        }
    } else {
        chats[chatObj.id] = JSON.parse(JSON.stringify(chatObj));
    }
}

export function registerChatEvent(contact: any) {
    try {
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            session.onMessage(handleChatMessage);
            session.onTyping(handleChatTyping);
        });
    } catch(error) {
        console.log(lp + "Error in add chat session", error);
    }
}

export function clearChatEvents(contact: any) {
    try {
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            session.cleanUpOnParticipantDisconnect();
        });
    } catch(error) {
        console.log(lp + "Error in add chat session", error);
    }
}
export async function getChatSession(contact: any) {
    try {
        const cnn = contact.getConnections().find((cnn: any) => cnn.getType() === connect.ConnectionType.AGENT);
        cnn.getMediaController().then((session: any) => {
            return session;
        });
    } catch(error) {
       return null;
    }
}
function handleChatMessage(event: any) {
    const { chatDetails, data } = event;
    const message = data;
    if (data.ContentType === "text/plain" || data.ContentType === 'text/markdown') {
        const transformedMessage: ChatMessage = {
            id: message.Id,
            senderId: message.ParticipantId,
            role: message.ParticipantRole,
            body: message.Content,
            bodyType: message.ContentType,
            timestamp: message.AbsoluteTime,
            webChatConversation: {
                id: chatDetails.contactId
            }
        };
        Emitter.emit(STATE_WEBCHATMESSAGE, {
            body: transformedMessage,
          });
        return transformedMessage;
    }  else if (data?.Type === 'ATTACHMENT') {
        const transformedMessage: ChatMessage = {
            id: message.Id,
            senderId: message.ParticipantId,
            role: message.ParticipantRole,
            body: message?.Attachments,
            bodyType: message.Type,
            timestamp: message.AbsoluteTime,
            webChatConversation: {
                id: chatDetails.contactId ? chatDetails.contactId : message?.ContactId
            }
        };
        Emitter.emit(STATE_WEBCHATMESSAGE, {
          body: transformedMessage,
        });
        return transformedMessage;
    }   
}

function handleChatTyping(event: any) {
    const { chatDetails, data } = event;
    const message = data;
    if (data?.ParticipantRole === 'CUSTOMER') {
        if (data.ContentType === "text/plain" || data.ContentType === 'text/markdown' || data.ContentType === 'application/vnd.amazonaws.connect.event.typing') {
            const transformedMessage: ChatMessage = {
                id: message?.Id,
                senderId: message?.ParticipantId,
                role: message?.ParticipantRole,
                body: message?.Content,
                bodyType: message?.ContentType,
                timestamp: message?.AbsoluteTime,
                webChatConversation: {
                    id: chatDetails?.contactId
                }
            };
            Emitter.emit(STATE_WEBCHATMESSAGE, {
                body: transformedMessage,
              });
            return transformedMessage;
        }
    }
}

export function handleIncomingChat(contact: any) {
    console.log(lp + 'INCOMING/RINGING chat contact event: ', contact, contact.getAttributes());
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.INCOMING, contact);
    return {contactId: contact.contactId || contact.getContactId(), eventType: CONTACT_EVENT_STATE_MAP.RINGING, channel: CHANNEL_TYPES.CHAT};
}

export function handleConnectingChat(contact: any) {
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.INCOMING, contact);
}

export function handleAcceptedChat(contact: any) {
    console.log(lp + 'ACCEPTED chat contact event:. Contact state is ' + contact.getStatus(), contact);
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.ACCEPTED, contact);
}

export function handleConnectedChat(contact: any) {
    console.log(lp + 'CONNECTED chat contact event:. Contact state is ' + contact.getStatus(), contact);
    registerChatEvent(contact);
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.CONNECTED, contact);
    // Send message to bg to open milo URL
    Emitter.emit(API_MILO_CHAT_CHANNEL_TRANSACTION, {
      body: {
        contactId: contact.contactId || contact.getContactId(),
      },
    });
    return {
        contactId: contact.contactId || contact.getContactId()
    };

}

export function handleRefreshChat(contact: any) {
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.REFRESH, contact);
}

export function handleMissedChat(contact: any) {
    console.log(lp + 'MISSED chat contact customer state: ', contact.getActiveInitialConnection(), contact);
    if(contact.getActiveInitialConnection() !== null) {
        changeAgentStateToAvailable();
    } else {
       console.log(`${lp} Contact ${contact.getContactId()} was missed but not changing agent status due to initial connection(customer) disconnected.`);
    }
    clearChatEvents(contact);
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.MISSED, contact);
    return {contactId: contact.contactId || contact.getContactId(), eventType: CONTACT_EVENT_STATE_MAP.MISSED, channel: CHANNEL_TYPES.CHAT};
}

export function handleDestroyChat(contact: any) {
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.DESTROYED, contact);
}

export function handleEndedChat(contact: any) {
    updateChatAndSend(CONTACT_EVENT_STATE_MAP.ENDED, contact);
}

export function updateChatAndSend(type: any, contact: any) {
    const existingChat = Object.assign({}, chats[contact.contactId || contact.getContactId()]);
    let chat = updateChat(type, contact);
    if (JSON.stringify(existingChat) === JSON.stringify(chat)) return;
    if(chat) {
        Emitter.emit(STATE_CHAT, { body: { ...chat }, success: true });
        setChat({...chat});
        return{...chat};
    }
    chat = null;
}

function updateChat(contactEventType: string, contact: any): Chat | null {
    try {
        let id = contact.contactId || contact.getContactId();
        let chat: any = chats[id];
        //Stop undefined self error
        if(!chat) {
            chat = defaultValue
        }
        switch(contactEventType) {
            case CONTACT_EVENT_STATE_MAP.INITIAL:
                let state = contact.getState();
                try { 
                    const cstate = contact.getState();
                    const ctype: string = cstate?.type;
                    let cstate_type: string = (CONTACT_EVENT_STATE_MAP as any)[ctype.toUpperCase()];
                    if(cstate_type === CONTACT_EVENT_STATE_MAP.ENDED || ctype === 'error') {
                        return chat;
                    }
                } catch(error) {
                    console.log(lp + "Error during state check of chat", contact, error);
                }
                // This handling handles initial scenerio when chat event arrieves first time
                if(!chat) {
                    chat = defaultValue
                }
                chat.id = contact.contactId || contact.getContactId();
                chat.self.type = 'AGENT';
                chat.self.id = contact.getAgentConnection().getConnectionId();
                chat.self.state = CONTACT_STATE_MAP.INCOMING; 
                chat.selfState = CONTACT_STATE_MAP.INCOMING; 
                chat.customer.state = CONTACT_STATE_MAP.CONNECTED; 
                chat.customer.type = 'CUSTOMER';
                chat.self.notes = '';
                chat.self.endTime = '';
                chat.self.connectedTime = undefined;
                chat.customer.connectedTime = state.timestamp;
                chat.customer.attributes = formatAttributes(contact.getAttributes());
                chat.customer.name = contact.getAttributes()?.customerName?.value || "";
                return chat;
            case CONTACT_EVENT_STATE_MAP.INCOMING:
                return chat;

            case CONTACT_EVENT_STATE_MAP.ACCEPTED:
            case CONTACT_EVENT_STATE_MAP.CONNECTED:
                chat.self.state = CONTACT_STATE_MAP.CONNECTED; 
                chat.selfState = CONTACT_STATE_MAP.CONNECTED;
                if (!chat.self.connectedTime) {
                    chat.self.connectedTime = new Date().getTime();
                }
                chat.customer.attributes = formatAttributes(contact.getAttributes());
                if(!chat.customer.name){
                    chat.customer.name = contact.getAttributes()?.customerName?.value || "";
                }
                return chat;
            case CONTACT_EVENT_STATE_MAP.REFRESH:
                const rstate = contact.getState();
                const rcontactId = contact.contactId || contact.getContactId();
                const type: string = rstate.type;
                if(type === 'error') {
                    if((chat && chat.id === rcontactId) || !chat?.id) {
                        disconnectChatContact(rcontactId);
                        return chat;
                    } else {
                        return chat;
                    }
                }

                let selfState: string = (CONTACT_STATE_MAP as any)[type.toUpperCase()];
                if(chat && chat.id === (contact.contactId || contact.getContactId()) && selfState !== CONTACT_EVENT_STATE_MAP.ENDED) {
                    chat.customer.attributes = formatAttributes(contact.getAttributes());
                }
              return chat;              
                
            case CONTACT_EVENT_STATE_MAP.MISSED:
                const contactId = contact.contactId || contact.getContactId();
                clearCurrentChatContact(contactId);
                return chat;
            case CONTACT_EVENT_STATE_MAP.DESTROYED:
            case CONTACT_EVENT_STATE_MAP.ENDED:
                if(!chat) {
                    chat = defaultValue;
                    chat.id = contact.contactId || contact.getContactId();
                    chat.self.state = CONTACT_STATE_MAP.ENDED; 
                    chat.selfState = CONTACT_STATE_MAP.ENDED;
                    return chat;
                }
                if(chat.id === (contact.contactId || contact.getContactId())) {
                    chat.self.state = CONTACT_STATE_MAP.ENDED; 
                    chat.selfState = CONTACT_STATE_MAP.ENDED;
                    if(!chat.self.endTime) {
                        chat.self.endTime = new Date().getTime();
                    }
                }
                return chat;
            default:
                return chat;
        }
    } catch(err) {
        console.log(lp + 'Error in updating chat' + contact, err);
        return null
    }
}