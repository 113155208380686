type TabCountCallback = (count: number) => void;

class TabCountSync {
  private count: number;
  private closed: boolean;
  private bc: BroadcastChannel;
  private subscribers: TabCountCallback[];

  constructor() {
    this.count = 1;
    this.closed = false;
    this.bc = new BroadcastChannel("tab-count-sync");
    this.bc.onmessage = this.onMessage.bind(this);
    this.subscribers = [];

    // Notify that this tab has opened and get the current count from other tabs
    this.bc.postMessage("opened");
  }

  private setCount(newCount: number, notify: boolean = true) {
    this.count = newCount;
    if (notify && !this.closed) {
      // Broadcast the updated count to other tabs
      this.bc.postMessage(this.count);
    }
    this.notifySubscribers();
  }

  private onMessage(event: MessageEvent) {
    const { data } = event;

    if (this.closed) {
      return;
    }

    if (data === "opened") {
      // Another tab has opened, so we increment the count
      this.setCount(this.count + 1);
    } else if (data === "closed") {
      // Another tab has closed, so we decrement the count
      this.setCount(this.count - 1);
    } else if (typeof data === "number") {
      // Received a count from another tab
      if (this.count > data) {
        // Send the current count if it's greater than the received count
        this.bc.postMessage(this.count);
      } else if (this.count !== data) {
        // Update the local count to match the received count
        this.setCount(data, false);
      }
    }
  }

  private notifySubscribers() {
    this.subscribers.forEach((callback) => callback(this.count));
  }

  public subscribe(callback: TabCountCallback) {
    this.subscribers.push(callback);
    // Notify the subscriber immediately with the current count
    callback(this.count);
  }

  public close() {
    if (!this.closed) {
      this.closed = true;
      this.bc.postMessage("closed");
      this.bc.close();
    }
  }
}

export default TabCountSync;
