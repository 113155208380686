import {
  Flex,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Text,
} from "@adobe/react-spectrum";

interface CallMonitorProps {
  monitorType: string;
  setMonitorType: (value: string) => void;
  isDisabled: boolean;
  handleCallMonitoring: () => void;
}

const CallMonitor: React.FC<CallMonitorProps> = (props) => {
  const { monitorType, setMonitorType, isDisabled, handleCallMonitoring } =
    props;
  return (
    <>
      <Flex direction={"column"} height={"100%"}>
        <Text UNSAFE_className="call-detail-heading" marginY={15}>
          Join call as
        </Text>
        <RadioGroup
          orientation="horizontal"
          value={monitorType}
          onChange={setMonitorType}
          isEmphasized
        >
          <Radio
            value="silent"
            UNSAFE_className="call-monitor-text"
            isDisabled={isDisabled}
          >
            Monitor
          </Radio>
          <Radio value="barge" UNSAFE_className="call-monitor-text" isDisabled>
            Barge-in
          </Radio>
        </RadioGroup>
        <Checkbox
          isEmphasized
          defaultSelected
          isDisabled
          isSelected
          UNSAFE_className="call-monitor-checkbox"
        >
          Mute me while joining the call
        </Checkbox>
        <Button
          maxWidth={"125px"}
          onPress={handleCallMonitoring}
          variant="accent"
          UNSAFE_className={"submit-call-monitor-btn"}
          isDisabled={isDisabled}
        >
          Submit request
        </Button>
      </Flex>
    </>
  );
};

export default CallMonitor;
