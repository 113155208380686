import registerAgentHandlers from './agent';
import registerCallHandlers from './call';
//import registerSessionHandlers from './session';
import registerHistoryHandlers from './history';
import registerCaseHandlers from './cases';
import registerCcpSessionHandlers from './session';
import registerWindowHandlers from './window';
import registerWebRTCHandlerEvents from './webrtc';
import { registerPermissionCheckEvents } from './permissionCheck';

export default function registerEventHandlerControlllers() {
    //registerSessionHandlers();
    registerWebRTCHandlerEvents();
    registerAgentHandlers();
    registerCallHandlers();
    registerHistoryHandlers();
    registerCaseHandlers();
    registerCcpSessionHandlers();
    registerWindowHandlers();
    registerPermissionCheckEvents();
}
