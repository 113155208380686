import { useEffect, useState } from "react";
import {
  View,
  Text,
  Flex,
  Heading,
  ComboBox,
  Item,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import {
  CallHangupIcon,
  ForwardPhoneCallIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnMmuteIcon,
} from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_HANGUP,
  API_CALL_HOLD,
  API_CALL_MUTE,
  API_CALL_PLAY,
} from "../../../constants/constants";
import { RootState } from "../../../store/storeConfig";
import storeRTK from "../../../store/storeRTK";
import { setMonitoringCall } from "../../../actions/call";
import { showErrorToast } from "../../../utils/utils";
import CallTransferDialog from "../CallTransferDialog";
import CallDialPad from "../CallDialer/CallDialpad";

export const LiveCall = () => {
  const [callTime, setCallTime] = useState(0);
  let call = useSelector((state: RootState) => state.call);
  let isOnline = useSelector((state: RootState) => state.session.isOnline);
  let [disconnectCall, setDisconnectCall] = useState(false);
  const [transferDialogState, setTransferDialogState] = useState(false);
  const [hold, setHold] = useState(true);

  useEffect(() => {
    if (isOnline === false) {
      setDisconnectCall(true);
    }
    if (isOnline && disconnectCall) {
      showErrorToast(
        "Call disconnected due to network/VPN disconnect",
        "callHangupToast"
      );
      hangupCallHandler();
      setDisconnectCall(false);
    }
  }, [isOnline]);

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval> | null = null;
    if (call?.self?.state === "ESTABLISHED") {
      const startTime = call?.self.connectedTime;
      timerId = setInterval(() => {
        setCallTime(Date.now() - startTime);
      }, 1000);
    } else {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    }
    return () => {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    };
  }, [call?.self?.state]);

  const formattedTime = () => {
    const hours = Math.floor(callTime / 3600000)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((callTime % 3600000) / 60000)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((callTime % 60000) / 1000)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const hangupCallHandler = () => {
    Emitter.emit(API_CALL_HANGUP, { body: { conversationId: call.id } });
    storeRTK.dispatch(setMonitoringCall(false));
  };

  const muteCallHandler = () => {
    Emitter.emit(API_CALL_MUTE, {
      body: { mute: !call?.self?.muted },
    });
  };

  const pauseCallHandler = () => {
    setHold(!hold);
    Emitter.emit(API_CALL_HOLD, { body: { held: hold } });
  };

  const playCallHandler = () => {
    setHold(!hold);
    Emitter.emit(API_CALL_PLAY, { body: { held: hold } });
  };

  return (
    <View
      borderColor="gray-200"
      borderWidth="thin"
      backgroundColor="gray-100"
      borderRadius={"regular"}
      UNSAFE_className="call-inProgress-container"
    >
      <View
        paddingStart="12px"
        borderStartWidth="thicker"
        borderStartColor="static-green-500"
        borderRadius="regular"
        UNSAFE_className="call-inProgress-container"
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <Flex direction="column">
            <Text UNSAFE_className="call-inProgress-heading">
              {call?.customer?.displayName}
            </Text>
            <Text UNSAFE_className="call-inProgress-font">
              {call?.self?.state !== "ESTABLISHED" ? "Dialing" : "on call"}{" "}
              {formattedTime()}
            </Text>
          </Flex>
          <Flex direction={"row"}>
            <Flex direction={"column"} alignItems="center"  UNSAFE_className="call-action-items">
              <button
                type="button"
                className="iconButton"
                onClick={() => {
                  setTransferDialogState(true);
                }}
              >
                <ForwardPhoneCallIcon />
              </button>
              <Text UNSAFE_className="call-inProgress-font">Transfer</Text>
            </Flex>
            <Flex direction={"column"} alignItems="center"  UNSAFE_className={`call-action-items ${call?.self?.muted ? 'muted' : ''}`}>
              <button
                type="button"
                className={
                  call?.self?.muted ? "iconButton active " : "iconButton"
                }
                onClick={muteCallHandler}
              >
                {call?.self?.muted ? <MuteIcon /> : <UnMmuteIcon />}
              </button>
              <Text UNSAFE_className="call-inProgress-font">
                {call?.self?.muted ? "Unmute" : "Mute"}
              </Text>
            </Flex>
            <Flex direction={"column"} alignItems="center"  UNSAFE_className="call-action-items">
              <button
                type="button"
                className="iconButton"
                disabled={call?.self?.state !== "ESTABLISHED"}
                onClick={hold ? playCallHandler : pauseCallHandler}
              >
                {hold ? <PauseIcon /> : <PlayIcon />}
              </button>
              <Text UNSAFE_className="call-inProgress-font">
                {hold ? "Hold" : "Resume"}
              </Text>
            </Flex>

            <Flex direction={"column"} alignItems="center" UNSAFE_className="call-action-items">
              <button
                type="button"
                className={"call-end-Button"}
                onClick={hangupCallHandler}
              >
                <CallHangupIcon />
              </button>
              <Text UNSAFE_className="call-inProgress-font">End</Text>
            </Flex>
          </Flex>
        </Flex>
      </View>
      {transferDialogState && (
        <CallTransferDialog
          isOpen={transferDialogState}
          onClose={() => setTransferDialogState(false)}
        />
      )}
    </View>
  );
};

const CallInProgress = () => {
  return (
    <>
      <Flex
        height={"100%"}
        width={"100%"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        <View paddingX={"1rem"} paddingTop={"1rem"}>
          <LiveCall />
        </View>
        <View>
          <View>
            <View paddingX={20}>
             <Text UNSAFE_className="call-combobox-heading">
              Consult
             </Text>
            </View>
            <View paddingX={20}>
              <ComboBox
                UNSAFE_className="call-inProgress-combobox"
                width={"100%"}
                label="Select speed dials"
                defaultInputValue="Manoj Kumar verma (Agent)"
                isDisabled
              >
                <Item key="agent-name">Manoj Kumar verma (Agent)</Item>
              </ComboBox>
            </View>
            <Flex width={"100%"} alignItems={"center"}>
              <View
                borderBottomWidth="thin"
                borderBottomColor="gray-200"
                width={"100%"}
              ></View>
              <View UNSAFE_className="dialer-text">Or dial manually</View>
              <View
                borderBottomWidth="thin"
                borderBottomColor="gray-200"
                width={"100%"}
              ></View>
            </Flex>
          </View>
          <Flex width={"100%"} alignItems={"center"} justifyContent={"center"} UNSAFE_className="dialer-container">
            <View paddingTop={"1rem"} paddingBottom={"1rem"}>
              <CallDialPad />
            </View>
          </Flex>
        </View>
      </Flex>
    </>
  );
};

export default CallInProgress;
