export const AdobeLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="control_strip_-_welcome"
        data-name="control strip - welcome"
        transform="translate(0.5 0.881)"
      >
        <g
          id="Group_67925"
          data-name="Group 67925"
          transform="translate(-0.5 -0.881)"
        >
          <rect id="ToDelete" width="24" height="24" fill="#fff" opacity="0" />
          <path
            id="Path_39000"
            data-name="Path 39000"
            d="M13.218,3.5H19.81V20.228Z"
            transform="translate(1.652 0.087)"
            fill="#000"
          />
          <path
            id="Path_39001"
            data-name="Path 39001"
            d="M9.1,3.5H2.5V20.228Z"
            transform="translate(0.123 0.087)"
            fill="#000"
          />
          <path
            id="Path_39002"
            data-name="Path 39002"
            d="M11.138,9.023l4.2,9.931H12.587l-1.259-3.163H8.252Z"
            transform="translate(0.951 1.078)"
            fill="#000"
          />
        </g>
      </g>
    </svg>
  );
};

export const UserArrowIcon = () => {
  return (
    <svg
      style={{ margin: "0 auto" }}
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      fill="000000"
    >
      <rect id="Canvas" fill="#E6E6E6" opacity="0" width="18" height="18" />
      <path
        fill="#FFFFFF"
        d="M5.437,9.811A.25.25,0,0,0,5,9.977V12H1.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5H5v1.909a.25.25,0,0,0,.437.166L9,13.5Z"
      />
      <path
        fill="#FFFFFF"
        d="M13.1275,9.8875a.719.719,0,0,1-.622-.7215V8.1245a.72049.72049,0,0,1,.1835-.465,5.5,5.5,0,0,0,1.25-3.433c0-2.6-1.378-4.05-3.4595-4.05s-3.5,1.509-3.5,4.05A5.562,5.562,0,0,0,8.302,7.673a.694.694,0,0,1,.172.45V9.186a.7.7,0,0,1-.684.697L11.2845,13.5,9.7895,15H17.968c.003-.3655.0055-.7085.0055-.763C17.9735,13.1555,17.2345,10.3045,13.1275,9.8875Z"
      />
    </svg>
  );
};

export const PlayIcon = () => {
  return (
    <svg
      style={{ margin: "0 auto" }}
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#464646" opacity="0" width="18" height="18" />
      <path
        fill="#464646"
        d="M4.73,2H3.5a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5H4.73a1,1,0,0,0,.5035-.136l11.032-6.433a.5.5,0,0,0,0-.862L5.2335,2.136A1,1,0,0,0,4.73,2Z"
      />
    </svg>
  );
};

export const PauseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      style={{ margin: "0 auto" }}
    >
      <rect id="Canvas" fill="#464646" opacity="0" width="18" height="18" />
      <rect fill="#464646" height="14" rx="0.5" width="4" x="3" y="2" />
      <rect fill="#464646" height="14" rx="0.5" width="4" x="10" y="2" />
    </svg>
  );
};

export const MuteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.95"
      height="15.821"
      viewBox="0 0 15.95 15.821"
    >
      <g
        id="Group_73062"
        data-name="Group 73062"
        transform="translate(0 -2.129)"
      >
        <path
          id="Path_13002"
          data-name="Path 13002"
          d="M6,13.5A5.492,5.492,0,0,1,8,9.261V2.5a.363.363,0,0,0-.6-.286l-3.319,3.4c-.22.223-.363.423-.711.423H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.372c.35,0,.5.205.711.422l2,2.05A5.507,5.507,0,0,1,6,13.5Z"
          fill="#464646"
        />
        <path
          id="Path_13003"
          data-name="Path 13003"
          d="M11.5,9.05a4.45,4.45,0,1,0,4.45,4.45A4.45,4.45,0,0,0,11.5,9.05ZM8,13.5a3.465,3.465,0,0,1,.738-2.126l4.889,4.889A3.483,3.483,0,0,1,8,13.5Zm6.262,2.126L9.374,10.738a3.483,3.483,0,0,1,4.888,4.889Z"
          fill="#464646"
        />
      </g>
    </svg>
  );
};

export const UnMmuteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <defs></defs>
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        fill="#464646"
        d="M3.3725,6.0365H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.3725a.714.714,0,0,1,.4655.1725L7.403,15.802A.3635.3635,0,0,0,8,15.5145V2.5a.363.363,0,0,0-.597-.2855l-3.5635,3.65A.72.72,0,0,1,3.3725,6.0365Z"
      />
      <path
        fill="#464646"
        d="M11.02,9a3.46751,3.46751,0,0,1-.7035,2.096.49.49,0,0,0,.043.644l.008.008a.496.496,0,0,0,.7435-.045A4.47751,4.47751,0,0,0,11.1,6.2765a.496.496,0,0,0-.742-.0435l-.0075.008a.491.491,0,0,0-.0425.646A3.47151,3.47151,0,0,1,11.02,9Z"
      />
      <path
        fill="#464646"
        d="M14.02,9a6.469,6.469,0,0,1-1.5575,4.2175.4865.4865,0,0,0,.0315.6585l.007.007a.5.5,0,0,0,.70711-.00167q.01566-.01574.02989-.03283a7.49,7.49,0,0,0-.013-9.7145.5.5,0,0,0-.70443-.0614q-.01558.01308-.03007.0274l-.007.0075a.48849.48849,0,0,0-.0335.6595A6.46848,6.46848,0,0,1,14.02,9Z"
      />
    </svg>
  );
};

export const VolumeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      style={{ margin: "0 auto" }}
    >
      <rect id="Canvas" fill="#ffffff" opacity="0" width="18" height="18" />
      <path
        fill="#ffffff"
        d="M3.3725,6.0365H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.3725a.714.714,0,0,1,.4655.1725L7.403,15.802A.3635.3635,0,0,0,8,15.5145V2.5a.363.363,0,0,0-.597-.2855l-3.5635,3.65A.72.72,0,0,1,3.3725,6.0365Z"
      />
      <path
        fill="#ffffff"
        d="M11.02,9a3.46751,3.46751,0,0,1-.7035,2.096.49.49,0,0,0,.043.644l.008.008a.496.496,0,0,0,.7435-.045A4.47751,4.47751,0,0,0,11.1,6.2765a.496.496,0,0,0-.742-.0435l-.0075.008a.491.491,0,0,0-.0425.646A3.47151,3.47151,0,0,1,11.02,9Z"
      />
      <path
        fill="#ffffff"
        d="M14.02,9a6.469,6.469,0,0,1-1.5575,4.2175.4865.4865,0,0,0,.0315.6585l.007.007a.5.5,0,0,0,.70711-.00167q.01566-.01574.02989-.03283a7.49,7.49,0,0,0-.013-9.7145.5.5,0,0,0-.70443-.0614q-.01558.01308-.03007.0274l-.007.0075a.48849.48849,0,0,0-.0335.6595A6.46848,6.46848,0,0,1,14.02,9Z"
      />
    </svg>
  );
};
export const CallHangupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.58"
      height="5.948"
      viewBox="0 0 16.58 5.948"
      style={{ margin: "0 auto" }}
    >
      <path
        id="Icon_map-volume-control-telephone"
        // dataName="Icon map-volume-control-telephone"
        d="M4.248,4.685H5.388c.342,0,.56-.5.56-.841V.257c0-.339-.218-.245-.56-.245H4.248Zm0,6.8H5.388c.342,0,.56.394.56.734V15.8c0,.337-.218.777-.56.777H4.248Zm-1.5-3.4c-.01-3.278.607-3.4.958-3.4h.121V.012H3.678C3.052.012,0,1.041,0,8.3S3.052,16.58,3.678,16.58h.145v-5.1H3.7C3.351,11.482,2.754,11.361,2.744,8.084Z"
        transform="translate(16.58) rotate(90)"
        fill="#fff"
      />
    </svg>
  );
};

export const MaximizeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        // class="a"
        d="M7.0385,10.3535a.5.5,0,0,0-.707,0l-3.242,3.242L1.6,12.103A.344.344,0,0,0,1.3525,12a.35.35,0,0,0-.35.35v4.42a.25.25,0,0,0,.227.23H5.65A.35.35,0,0,0,6,16.65a.34252.34252,0,0,0-.1035-.245l-1.492-1.4945,3.242-3.242a.5.5,0,0,0,0-.707Z"
        fill="#ffffff"
      />
      <path
        // class="a"
        d="M16.7705,1H12.35a.35.35,0,0,0-.35.35V1.3525a.34253.34253,0,0,0,.1035.245l1.492,1.492-3.242,3.242a.5.5,0,0,0,0,.707l.608.608a.5.5,0,0,0,.707,0l3.242-3.242,1.492,1.4925A.344.344,0,0,0,16.65,6,.35.35,0,0,0,17,5.65h0V1.2295A.25.25,0,0,0,16.7705,1Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      style={{ margin: "0 auto" }}
    >
      <rect id="Canvas" fill="#ffffff" opacity="0" width="18" height="18" />
      <path
        fill="#ffffff"
        d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z"
      />
    </svg>
  );
};

export const CallTransferIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ margin: "0 auto" }}
    >
      <defs>
        <clipPath id="clipPath">
          <rect width="16" height="16" fill="none" />
        </clipPath>
      </defs>
      <g
        id="Repeat_Grid_4808"
        // dataName="Repeat Grid 4808"
        clipPath="url(#clipPath)"
      >
        <g transform="translate(-1662 -895)">
          <circle
            id="Ellipse_9633"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1656 -895)">
          <circle
            id="Ellipse_9633-2"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1650 -895)">
          <circle
            id="Ellipse_9633-3"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1662 -889)">
          <circle
            id="Ellipse_9633-4"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1656 -889)">
          <circle
            id="Ellipse_9633-5"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1650 -889)">
          <circle
            id="Ellipse_9633-6"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1662 -883)">
          <circle
            id="Ellipse_9633-7"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1656 -883)">
          <circle
            id="Ellipse_9633-8"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
        <g transform="translate(-1650 -883)">
          <circle
            id="Ellipse_9633-9"
            // dataName="Ellipse 9633"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1662 895)"
            fill="#464646"
          />
        </g>
      </g>
    </svg>
  );
};

export const UpArrowIcon = () => {
  return (
    <svg
      id="Action_button"
      data-name="Action button"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Placement_Area"
        data-name="Placement Area"
        fill="red"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="40" height="40" stroke="none" />
        <rect x="0.5" y="0.5" width="39" height="39" fill="none" />
      </g>
      <rect
        id="Background"
        width="40"
        height="40"
        rx="4"
        fill="rgba(255,0,0,0)"
      />
      <g id="Icon" transform="translate(10 10)">
        <g id="Canvas" fill="#fff" stroke="#a2a2a2" strokeWidth="1" opacity="0">
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <path
          id="Path_83677"
          data-name="Path 83677"
          d="M15.107,11.544a1.111,1.111,0,0,1-1.895.784L9.558,8.677,5.9,12.328a1.111,1.111,0,0,1-1.6-1.541l.027-.027,4.44-4.436a1.111,1.111,0,0,1,1.569,0l4.438,4.435a1.1,1.1,0,0,1,.326.784Z"
          transform="translate(0.449 0.667)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
export const UpArrowRedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      style={{
        background: props.background,
        borderRadius: "2px 2px 0px 0px",
      }}
    >
      <rect id="Canvas" fill="red" opacity="0" width="20" height="20" />
      <path
        fill={props.color}
        d="M14,10.99a1,1,0,0,1-1.7055.7055l-3.289-3.286-3.289,3.286a1,1,0,0,1-1.437-1.3865l.0245-.0245L8.3,6.2925a1,1,0,0,1,1.4125,0L13.707,10.284A.9945.9945,0,0,1,14,10.99Z"
      />
    </svg>
  );
};
export const DownArrowRedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 20 20"
      width="20"
      style={{
        background: props.background,
        borderRadius: "2px 2px 0px 0px",
      }}
    >
      <rect
        id="Canvas"
        fill={props.background}
        opacity="0"
        width="20"
        height="20"
      />
      <path
        fill={props.color}
        d="M4,7.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,11.7075a1,1,0,0,1-1.4125,0L4.293,7.716A.9945.9945,0,0,1,4,7.01Z"
      />
    </svg>
  );
};
export const BackArrowIcon = () => {
  return (
    <svg
      id="Group_2"
      data-name="Group 2"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
    >
      <rect id="ToDelete" width="14" height="10" fill="#ff13dc" opacity="0" />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M12.99,4H3.414L5.7,1.717A1,1,0,1,0,4.283.3L.3,4.293a1,1,0,0,0,0,1.415L4.283,9.7A1,1,0,1,0,5.7,8.283L3.414,6H12.99a1,1,0,0,0,0-2Z"
        fill="#6e6e6e"
      />
    </svg>
  );
};

export const ChevronRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        fill="#6E6E6E"
        d="M12,9a.994.994,0,0,1-.2925.7045l-3.9915,3.99a1,1,0,1,1-1.4355-1.386l.0245-.0245L9.5905,9,6.3045,5.715A1,1,0,0,1,7.691,4.28l.0245.0245,3.9915,3.99A.994.994,0,0,1,12,9Z"
      />
    </svg>
  );
};

export const ChevronLeft = ({ fill }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      fill={fill}
    >
      <rect
        id="Canvas"
        fill={fill || "#ff13dc"}
        opacity="0"
        width="18"
        height="18"
      />
      <path
        className="fill"
        d="M6,9a.994.994,0,0,0,.2925.7045l3.9915,3.99a1,1,0,1,0,1.4355-1.386l-.0245-.0245L8.4095,9l3.286-3.285A1,1,0,0,0,10.309,4.28l-.0245.0245L6.293,8.2945A.994.994,0,0,0,6,9Z"
      />
    </svg>
  );
};

export const UserGroup = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        fill="#6E6E6E"
        d="M13.461,10.238c-.7205-.0625-.732-.642-.732-1.3645A6.57549,6.57549,0,0,0,14.274,4.955C14.274,2.582,12.924,1,10.9795,1A3.15,3.15,0,0,0,9.64,1.287a5.34543,5.34543,0,0,1,2.62,4.95,7.80009,7.80009,0,0,1-1.21,3.9745.43051.43051,0,0,0,.237.644A6.744,6.744,0,0,1,15.8895,15H17.518a.4355.4355,0,0,0,.44022-.43072q.00016-.01515-.00072-.03028C17.668,11.3945,14.446,10.324,13.461,10.238Z"
      />
      <path
        fill="#6E6E6E"
        d="M14.4865,17a.46551.46551,0,0,0,.47134-.45959q.00021-.01721-.00084-.03441c-.31-3.367-3.7625-4.514-4.818-4.606-.772-.067-.7845-.6885-.7845-1.4625a7.04646,7.04646,0,0,0,1.6555-4.2C11.01,3.6955,9.563,2,7.4795,2S3.95,3.6955,3.95,6.2385a7.04646,7.04646,0,0,0,1.6555,4.2c0,.774-.0125,1.3955-.7845,1.4625C3.7645,11.992.312,13.139,0,16.506a.4655.4655,0,0,0,.43606.49319Q.45426,17.00032.4725,17Z"
      />
    </svg>
  );
};

export const CheckboxCheked = () => {
  return (
    <svg
      id="Checkbox"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="32"
      viewBox="0 0 14 32"
    >
      <g
        id="Placement_Area"
        data-name="Placement Area"
        fill="red"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="14" height="32" stroke="none" />
        <rect x="0.5" y="0.5" width="13" height="31" fill="none" />
      </g>
      <g
        id="Box"
        transform="translate(0 9)"
        fill="#1473e6"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      >
        <rect width="14" height="14" rx="2" stroke="none" />
        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill="none" />
      </g>
      <g id="Checkmark">
        <g
          id="Frame"
          transform="translate(2 11)"
          fill="#f0f"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="10" height="10" stroke="none" />
          <rect x="0.5" y="0.5" width="9" height="9" fill="none" />
        </g>
        <path
          id="Shape"
          d="M3.788,9A1,1,0,0,1,3,8.615l-2.288-3a1,1,0,1,1,1.576-1.23l1.5,1.991L7.712,1.385a1,1,0,1,1,1.576,1.23l-4.712,6A1,1,0,0,1,3.788,9Z"
          transform="translate(2 11)"
          fill="#fafafa"
        />
      </g>
    </svg>
  );
};

export const CheckboxUnCheked = () => {
  return (
    <svg
      id="Checkbox"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="32"
      viewBox="0 0 14 32"
    >
      <g
        id="Placement_Area"
        data-name="Placement Area"
        fill="red"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="14" height="32" stroke="none" />
        <rect x="0.5" y="0.5" width="13" height="31" fill="none" />
      </g>
      <g
        id="Box"
        transform="translate(0 9)"
        fill="#fafafa"
        stroke="#8e8e8e"
        strokeWidth="2"
      >
        <rect width="14" height="14" rx="2" stroke="none" />
        <rect x="1" y="1" width="12" height="12" rx="1" fill="none" />
      </g>
    </svg>
  );
};

export const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 18 18"
    width="18"
  >
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
    <path
      fill="#6E6E6E"
      d="M4,7.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,11.7075a1,1,0,0,1-1.4125,0L4.293,7.716A.9945.9945,0,0,1,4,7.01Z"
    />
  </svg>
);

export const CallCenter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 18 18"
    width="18"
  >
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
    <path
      fill="#6E6E6E"
      d="M15.5455,7H14.818A5.9115,5.9115,0,0,0,9,1,5.9115,5.9115,0,0,0,3.182,7H2.4545A1.478,1.478,0,0,0,1,8.5v3A1.478,1.478,0,0,0,2.4545,13h2.182V7H4.6A4.47051,4.47051,0,0,1,9,2.4625,4.47051,4.47051,0,0,1,13.4,7h-.0365v5.669a5.09151,5.09151,0,0,1-3.1055,2.4A1.55753,1.55753,0,0,0,9,14.5a1.32171,1.32171,0,0,0-1.4545,1.125A1.32171,1.32171,0,0,0,9,16.75a1.394,1.394,0,0,0,1.4295-.9345A5.841,5.841,0,0,0,14.0275,13h1.518A1.478,1.478,0,0,0,17,11.5v-3A1.478,1.478,0,0,0,15.5455,7Z"
    />
  </svg>
);

export const NotReady = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69.147"
      height="69.147"
      viewBox="0 0 69.147 69.147"
    >
      <g
        id="Group_160945"
        data-name="Group 160945"
        transform="translate(-896.602 -183)"
      >
        <line
          id="Line_34"
          data-name="Line 34"
          x2="67"
          transform="translate(897.602 251.147)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_150549"
          data-name="Rectangle 150549"
          width="14"
          height="15"
          rx="7"
          transform="translate(903.602 208.147)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83098"
          data-name="Path 83098"
          d="M904.1,367.826a6.5,6.5,0,0,1-6.5-6.5v-8.664A8.662,8.662,0,0,1,906.266,344h8.664a8.671,8.671,0,0,1,8.393,6.5"
          transform="translate(0 -116.679)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83099"
          data-name="Path 83099"
          d="M993.428,385.328H969.6L971.768,368h23.827Z"
          transform="translate(-52.506 -134.181)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83100"
          data-name="Path 83100"
          d="M921.6,384v6.5a2.166,2.166,0,0,0,2.166,2.166h8.664A4.332,4.332,0,0,1,928.1,397h-6.5"
          transform="translate(-17.502 -145.849)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <ellipse
          id="Ellipse_9613"
          data-name="Ellipse 9613"
          cx="2"
          cy="2.5"
          rx="2"
          ry="2.5"
          transform="translate(927.602 240.147)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <g
          id="Group_160943"
          data-name="Group 160943"
          transform="translate(911.681 184)"
        >
          <path
            id="Path_83101"
            data-name="Path 83101"
            d="M985.342,236.332a27.074,27.074,0,1,0-35.74-32.837"
            transform="translate(-949.602 -184)"
            fill="none"
            stroke="#7c7c7c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_83102"
            data-name="Path 83102"
            d="M1045.6,216v18.411l9.747,9.747"
            transform="translate(-1019.609 -207.336)"
            fill="none"
            stroke="#7c7c7c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <g
          id="Group_160944"
          data-name="Group 160944"
          transform="translate(948.504 242.552)"
        >
          <path
            id="Rectangle_150550"
            data-name="Rectangle 150550"
            d="M0,0H9A0,0,0,0,1,9,0V4.5A4.5,4.5,0,0,1,4.5,9h0A4.5,4.5,0,0,1,0,4.5V0A0,0,0,0,1,0,0Z"
            transform="translate(3.098 -0.405)"
            fill="none"
            stroke="#7c7c7c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_83103"
            data-name="Path 83103"
            d="M1088.851,410.5a3.249,3.249,0,0,1,0-6.5"
            transform="translate(-1085.602 -402.985)"
            fill="none"
            stroke="#7c7c7c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const Ready = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87.137"
      height="61.317"
      viewBox="0 0 87.137 61.317"
    >
      <g
        id="Group_160942"
        data-name="Group 160942"
        transform="translate(-1964.602 -1274.911)"
      >
        <line
          id="Line_32"
          data-name="Line 32"
          x2="74.197"
          transform="translate(1965.602 1335.229)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_150546"
          data-name="Rectangle 150546"
          width="17.458"
          height="19.64"
          rx="8.729"
          transform="translate(1978.695 1280.672)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83091"
          data-name="Path 83091"
          d="M2014.558,1436.717v-13.732A10.986,10.986,0,0,0,2003.573,1412h-10.985a10.985,10.985,0,0,0-10.985,10.985v10.985a8.239,8.239,0,0,0,8.239,8.239"
          transform="translate(-10.508 -107.252)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83092"
          data-name="Path 83092"
          d="M2110.558,1410.21h10.985l2.746-30.21h-43.942l-2.746,30.21h13.731"
          transform="translate(-73.551 -86.237)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83093"
          data-name="Path 83093"
          d="M2005.6,1452v8.239a2.746,2.746,0,0,0,2.747,2.746h10.985a5.493,5.493,0,0,1-5.493,5.493H2005.6"
          transform="translate(-26.268 -133.52)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <ellipse
          id="Ellipse_9609"
          data-name="Ellipse 9609"
          cx="2.728"
          cy="2.182"
          rx="2.728"
          ry="2.182"
          transform="translate(2024.523 1302.495)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83094"
          data-name="Path 83094"
          d="M2132.587,1436l2.746,19.224-2.746,2.747H2121.6l4.12-4.12,1.373-17.851Z"
          transform="translate(-102.446 -123.013)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Rectangle_150547"
          data-name="Rectangle 150547"
          d="M3.273,0h0a0,0,0,0,1,0,0V6.547a0,0,0,0,1,0,0h0A3.273,3.273,0,0,1,0,3.273v0A3.273,3.273,0,0,1,3.273,0Z"
          transform="translate(1972.148 1287.219)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Rectangle_150548"
          data-name="Rectangle 150548"
          d="M3.273,0H5.456a0,0,0,0,1,0,0V6.547a0,0,0,0,1,0,0H3.273A3.273,3.273,0,0,1,0,3.273v0A3.273,3.273,0,0,1,3.273,0Z"
          transform="translate(2004.882 1293.766) rotate(-180)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_83095"
          data-name="Path 83095"
          d="M1993.6,1340.359a12.359,12.359,0,0,1,24.718,0"
          transform="translate(-18.388 -52.089)"
          fill="none"
          stroke="#7c7c7c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const Alert = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#FF13DC" opacity="0" width="18" height="18" />
      <path
        fill="#D7373F"
        d="M8.5635,1.2895.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.4365,1.2895a.5.5,0,0,0-.873,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z"
      />
    </svg>
  );
};
export const AlertWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <rect id="Canvas" fill="#FF13DC" opacity="0" width="24" height="24" />
      <path
        fill="#FFFFFF"
        d="M8.5635,1.2895.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.4365,1.2895a.5.5,0,0,0-.873,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z"
      />
    </svg>
  );
};

export const CheveronUpDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        // class="a"
        d="M14,5.99a1,1,0,0,1-1.7055.7055L9.006,3.409,5.717,6.695A1,1,0,0,1,4.28,5.3085l.0245-.0245L8.3,1.2925a1,1,0,0,1,1.4125,0L13.707,5.284A.99446.99446,0,0,1,14,5.99Z"
      />
      <path
        // class="a"
        d="M4,12.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,16.707a1,1,0,0,1-1.4125,0L4.293,12.7155A.99452.99452,0,0,1,4,12.01Z"
      />
    </svg>
  );
};

export const Send = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#2C2C2C" opacity="0" width="18" height="18" />
      <path
        // class="a"
        d="M16.5955,2.5565.9,7.239a.25.25,0,0,0-.0405.4635L4.82,9.679Z"
      />
      <path
        // class="a"
        d="M6.5445,10.516l5.9685,3a.5.5,0,0,0,.67121-.22243l.00029-.00057L17.818,3.182Z"
      />
      <path
        // class="a"
        d="M5.04,11.625v3.8195a.3565.3565,0,0,0,.587.272l2.68-2.258Z"
      />
    </svg>
  );
};

export const Book = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#2C2C2C" opacity="0" width="18" height="18" />
      <path
        // class="a"
        d="M9.891,14H4.9975a2,2,0,1,1,0-4H10.259a.5.5,0,0,0,.4-.2l5.55-7.4a.25.25,0,0,0-.2-.4H8.0125a.5.5,0,0,0-.4.2L1.761,9.664A3.9905,3.9905,0,0,0,4.9845,16H10.259a.5.5,0,0,0,.4-.2l5.55-7.4a.25.25,0,0,0-.2-.4H14.391Z"
      />
    </svg>
  );
};

export const ChatRinging = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        // class="a"
        d="M3.3725,6.0365H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.3725a.714.714,0,0,1,.4655.1725L7.403,15.802A.3635.3635,0,0,0,8,15.5145V2.5a.363.363,0,0,0-.597-.2855l-3.5635,3.65A.72.72,0,0,1,3.3725,6.0365Z"
      />
      <path
        // class="a"
        d="M11.02,9a3.46751,3.46751,0,0,1-.7035,2.096.49.49,0,0,0,.043.644l.008.008a.496.496,0,0,0,.7435-.045A4.47751,4.47751,0,0,0,11.1,6.2765a.496.496,0,0,0-.742-.0435l-.0075.008a.491.491,0,0,0-.0425.646A3.47151,3.47151,0,0,1,11.02,9Z"
      />
      <path
        // class="a"
        d="M14.02,9a6.469,6.469,0,0,1-1.5575,4.2175.4865.4865,0,0,0,.0315.6585l.007.007a.5.5,0,0,0,.70711-.00167q.01566-.01574.02989-.03283a7.49,7.49,0,0,0-.013-9.7145.5.5,0,0,0-.70443-.0614q-.01558.01308-.03007.0274l-.007.0075a.48849.48849,0,0,0-.0335.6595A6.46848,6.46848,0,0,1,14.02,9Z"
      />
    </svg>
  );
};

export const Circle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <circle className="a" cx="9" cy="9" r="8" />
    </svg>
  );
};

export const File = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M8.1035,15.7785a3.32,3.32,0,0,1-2.364.985h-.053A3.488,3.488,0,0,1,3.273,15.726a3.382,3.382,0,0,1-.09763-4.78187L3.223,10.8955l8.8895-8.9A2.437,2.437,0,0,1,13.679,1.256a1.86,1.86,0,0,1,1.521.56,1.76853,1.76853,0,0,1,.5585,1.534,2.87,2.87,0,0,1-.792,1.5L9.036,10.7705c-.382.3825-.7415.6575-1.15.25s-.088-.7845.263-1.1355c.1335-.1335,4.124-4.119,4.8365-4.8295a.366.366,0,0,0,.007-.5105l-.3375-.359a.3675.3675,0,0,0-.51948-.01578L12.127,4.1785,7.15,9.172a1.816,1.816,0,0,0-.036,2.7345c1.3305,1.33,2.8415-.2955,2.8415-.2955L15.85,5.733a3.30462,3.30462,0,0,0,.236-4.8A3.1135,3.1135,0,0,0,13.7945,0a3.6375,3.6375,0,0,0-2.566,1.1135L2.38,9.95a4.7165,4.7165,0,0,0,6.67,6.67l7.7025-7.7a.36749.36749,0,0,0,0-.519l-.375-.3755a.3675.3675,0,0,0-.5195,0Z"
      />
    </svg>
  );
};

export const CloseIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="14"
      viewBox="0 0 18 18"
      width="14"
    >
      <defs></defs>
      <title>S Close 18 N</title>
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z"
      />
    </svg>
  );
};

export const MessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g
        id="Decorative_icon"
        data-name="Decorative icon"
        transform="translate(0)"
      >
        <rect
          id="Placement_Area"
          data-name="Placement Area"
          width="48"
          height="48"
          transform="translate(0)"
          fill="red"
          opacity="0"
        />
        <g id="Icon" transform="translate(0)">
          <rect id="Canvas" width="48" height="48" fill="#747474" opacity="0" />
          <path
            id="Path_12990"
            data-name="Path 12990"
            d="M46.5,1H1.5A1.529,1.529,0,0,0,0,2.556V36.777a1.529,1.529,0,0,0,1.5,1.556H21.212a15.216,15.216,0,0,1-.479-2.667H2.667V32.477L17.553,21.333,22.4,24.966a2.623,2.623,0,0,0,1.136.459,15.313,15.313,0,0,1,9.629-6.159l12.168-9.153V22.186A15.414,15.414,0,0,1,48,24.8V2.556A1.529,1.529,0,0,0,46.5,1ZM2.667,29.142V10.175l12.667,9.492ZM24,22.833,2.667,6.84V3.667H45.333V6.779Z"
            transform="translate(0 1.667)"
            fill="#747474"
          />
          <path
            id="Path_12991"
            data-name="Path 12991"
            d="M20.917,9.05A11.867,11.867,0,1,0,32.783,20.917,11.867,11.867,0,0,0,20.917,9.05Zm0,21.2a9.288,9.288,0,0,1-7.367-15L26.586,28.283A9.246,9.246,0,0,1,20.917,30.25Zm7.367-3.664L15.247,13.55A9.288,9.288,0,0,1,28.283,26.586Z"
            transform="translate(15.083 15.083)"
            fill="#747474"
          />
        </g>
      </g>
    </svg>
  );
};

export const HeadphoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <defs></defs>
      <title>S CallCenter 18 N</title>
      <rect id="Canvas" fill="#222222" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M15.5455,7H14.818A5.9115,5.9115,0,0,0,9,1,5.9115,5.9115,0,0,0,3.182,7H2.4545A1.478,1.478,0,0,0,1,8.5v3A1.478,1.478,0,0,0,2.4545,13h2.182V7H4.6A4.47051,4.47051,0,0,1,9,2.4625,4.47051,4.47051,0,0,1,13.4,7h-.0365v5.669a5.09151,5.09151,0,0,1-3.1055,2.4A1.55753,1.55753,0,0,0,9,14.5a1.32171,1.32171,0,0,0-1.4545,1.125A1.32171,1.32171,0,0,0,9,16.75a1.394,1.394,0,0,0,1.4295-.9345A5.841,5.841,0,0,0,14.0275,13h1.518A1.478,1.478,0,0,0,17,11.5v-3A1.478,1.478,0,0,0,15.5455,7Z"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <defs></defs>
      <title>S RealTimeCustomerProfile 18 N</title>
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M9,.5A8.5,8.5,0,1,0,17.5,9,8.5,8.5,0,0,0,9,.5Zm5.491,13.59161a5.41289,5.41289,0,0,0-3.11213-1.56415.65361.65361,0,0,1-.5655-.65569V10.9256a.65656.65656,0,0,1,.16645-.42218A4.99536,4.99536,0,0,0,12.12006,7.3855c0-2.36029-1.25416-3.67963-3.14337-3.67963s-3.179,1.36835-3.179,3.67963A5.05147,5.05147,0,0,0,6.9892,10.5047a.655.655,0,0,1,.16656.42206v.94165a.64978.64978,0,0,1-.57006.65539,5.43158,5.43158,0,0,0-3.11963,1.5205,7.49965,7.49965,0,1,1,11.025.04731Z"
      />
    </svg>
  );
};

export const WarningIcon = () => {
  return (
    <svg
      id="Decorative_icon"
      data-name="Decorative icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <rect
        id="Placement_Area"
        data-name="Placement Area"
        width="18"
        height="18"
        fill="red"
        opacity="0"
      />
      <g id="Icon">
        <rect id="Canvas" width="18" height="18" fill="#747474" opacity="0" />
        <path
          id="Path_12984"
          data-name="Path 12984"
          d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM7.7,3.388a.356.356,0,0,1,.169-.338,3.123,3.123,0,0,1,1.112-.229,3.431,3.431,0,0,1,1.116.172.388.388,0,0,1,.2.343V4.562c0,1.443-.288,5.445-.342,5.973a.264.264,0,0,1-.265.262l-.028,0H8.3a.284.284,0,0,1-.289-.236c-.05-.682-.311-4.55-.311-5.945ZM9,14.425a1.287,1.287,0,0,1-1.4-1.316,1.33,1.33,0,0,1,1.308-1.352q.046,0,.092,0a1.316,1.316,0,0,1,1.4,1.229q0,.061,0,.121A1.287,1.287,0,0,1,9,14.425Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const CallIcon = ({ fill = "#464646" }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.3"
      height="14.3"
      viewBox="0 0 14.3 14.3"
    >
      <path
        id="call_24dp_E8EAED_FILL0_wght200_GRAD0_opsz24"
        d="M173.064-786a10.5,10.5,0,0,1-4.536-1.108,15.527,15.527,0,0,1-4.318-3.111,15.77,15.77,0,0,1-3.1-4.318A10.447,10.447,0,0,1,160-799.064a.916.916,0,0,1,.263-.668.882.882,0,0,1,.656-.267h2.164a.935.935,0,0,1,.623.225,1.111,1.111,0,0,1,.36.576l.434,2.086a1.42,1.42,0,0,1-.022.641.917.917,0,0,1-.291.449l-1.92,1.79a13.8,13.8,0,0,0,1.19,1.817,18.072,18.072,0,0,0,1.391,1.589,15,15,0,0,0,1.641,1.418,17.029,17.029,0,0,0,1.928,1.238l1.871-1.9a1.019,1.019,0,0,1,.48-.3,1.469,1.469,0,0,1,.59-.03l1.841.377a1.048,1.048,0,0,1,.579.359.957.957,0,0,1,.222.623v2.13a.882.882,0,0,1-.267.656A.915.915,0,0,1,173.064-786Zm-11.208-9.053,1.679-1.545a.313.313,0,0,0,.109-.185.41.41,0,0,0-.009-.219l-.389-1.854a.36.36,0,0,0-.118-.2.34.34,0,0,0-.219-.067h-1.8a.228.228,0,0,0-.168.067.228.228,0,0,0-.067.168,8.3,8.3,0,0,0,.281,1.873A12.852,12.852,0,0,0,161.856-795.053Zm7.394,7.293a7.879,7.879,0,0,0,1.89.649,9.633,9.633,0,0,0,1.749.226.228.228,0,0,0,.168-.067.228.228,0,0,0,.067-.168v-1.757a.34.34,0,0,0-.067-.219.361.361,0,0,0-.2-.118l-1.619-.332a.277.277,0,0,0-.177-.008.427.427,0,0,0-.16.109ZM161.856-795.053ZM169.25-787.76Z"
        transform="translate(-159.85 800.15)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
    </svg>
  );
};
export const CallIconWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g id="Icon" transform="translate(-21)">
        <rect
          id="Frame"
          width="22"
          height="22"
          transform="translate(21)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="call_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24_1_"
          data-name="call_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 (1)"
          d="M136.95-822a15,15,0,0,1-6.175-1.363,18.468,18.468,0,0,1-5.55-3.862,18.465,18.465,0,0,1-3.862-5.55A15.008,15.008,0,0,1,120-838.951a1.018,1.018,0,0,1,.3-.75,1.019,1.019,0,0,1,.75-.3h4.05a.931.931,0,0,1,.625.237.915.915,0,0,1,.325.563l.65,3.5a1.764,1.764,0,0,1-.025.675,1.054,1.054,0,0,1-.275.476l-2.425,2.45a12.08,12.08,0,0,0,1.188,1.788,17.606,17.606,0,0,0,1.512,1.663,17.736,17.736,0,0,0,1.625,1.437A15.958,15.958,0,0,0,130.1-826l2.35-2.35a1.4,1.4,0,0,1,.588-.337,1.614,1.614,0,0,1,.712-.062l3.45.7a1.154,1.154,0,0,1,.575.363.88.88,0,0,1,.225.588v4.049a1.02,1.02,0,0,1-.3.751A1.019,1.019,0,0,1,136.95-822Zm-13.925-12,1.65-1.65L124.25-838h-2.225a20.54,20.54,0,0,0,.35,2.025A10.929,10.929,0,0,0,123.025-834Zm8.95,8.95a12.393,12.393,0,0,0,1.988.676,12.178,12.178,0,0,0,2.038.325v-2.2l-2.35-.475ZM123.025-834ZM131.975-825.051Z"
          transform="translate(-97 842)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const CallbackIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.3"
      height="14.3"
      viewBox="0 0 14.3 14.3"
    >
      <path
        id="phone_callback_24dp_E8EAED_FILL0_wght200_GRAD0_opsz24"
        d="M169.3-794.683h2.443a.423.423,0,0,1,.312.126.424.424,0,0,1,.126.312.422.422,0,0,1-.126.312.424.424,0,0,1-.312.125h-3.231a.684.684,0,0,1-.5-.2.684.684,0,0,1-.2-.5v-3.231a.423.423,0,0,1,.126-.312.424.424,0,0,1,.312-.126.422.422,0,0,1,.312.126.424.424,0,0,1,.125.312v2.443l4.469-4.469a.441.441,0,0,1,.3-.131.409.409,0,0,1,.318.131.43.43,0,0,1,.14.31.43.43,0,0,1-.14.31ZM173.064-786a10.5,10.5,0,0,1-4.536-1.108,15.527,15.527,0,0,1-4.318-3.111,15.77,15.77,0,0,1-3.1-4.318A10.447,10.447,0,0,1,160-799.064a.916.916,0,0,1,.263-.668.882.882,0,0,1,.656-.267h2.164a.935.935,0,0,1,.623.225,1.111,1.111,0,0,1,.36.576l.434,2.086a1.42,1.42,0,0,1-.022.641.917.917,0,0,1-.291.449l-1.92,1.79a13.8,13.8,0,0,0,1.19,1.817,18.072,18.072,0,0,0,1.391,1.589,15,15,0,0,0,1.641,1.418,17.029,17.029,0,0,0,1.928,1.238l1.871-1.9a1.019,1.019,0,0,1,.48-.3,1.469,1.469,0,0,1,.59-.03l1.841.377a1.048,1.048,0,0,1,.579.359.957.957,0,0,1,.222.623v2.13a.882.882,0,0,1-.267.656A.915.915,0,0,1,173.064-786Zm-11.208-9.053,1.679-1.545a.313.313,0,0,0,.109-.185.41.41,0,0,0-.009-.219l-.389-1.854a.36.36,0,0,0-.118-.2.34.34,0,0,0-.219-.067h-1.8a.228.228,0,0,0-.168.067.228.228,0,0,0-.067.168,8.3,8.3,0,0,0,.281,1.873A12.852,12.852,0,0,0,161.856-795.053Zm7.394,7.293a7.879,7.879,0,0,0,1.89.649,9.633,9.633,0,0,0,1.749.226.228.228,0,0,0,.168-.067.228.228,0,0,0,.067-.168v-1.757a.34.34,0,0,0-.067-.219.361.361,0,0,0-.2-.118l-1.619-.332a.277.277,0,0,0-.177-.008.427.427,0,0,0-.16.109ZM161.856-795.053ZM169.25-787.76Z"
        transform="translate(-159.85 800.15)"
        fill={color}
        stroke="#464646"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export const VoicemailIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={color}
    >
      <path
        d="M260-320q-75 0-127.5-52.5T80-500q0-75 52.5-127.5T260-680q75 0 127.5 52.5T440-500q0 27-8 52t-22 48h140q-14-23-22-48t-8-52q0-75 52.5-127.5T700-680q75 0 127.5 52.5T880-500q0 75-52.5 127.5T700-320H260Zm0-80q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm440 0q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Z"
        fill={color}
      />
    </svg>
  );
};

export const ChatIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      id="Decorative_icon"
      data-name="Decorative icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <rect
        id="Placement_Area"
        data-name="Placement Area"
        width="18"
        height="18"
        fill="red"
        opacity="0"
      />
      <g id="Icon">
        <rect id="Canvas" width="18" height="18" fill="#464646" opacity="0" />
        <path
          id="Path_12975"
          data-name="Path 12975"
          d="M9.5,7a.5.5,0,0,1,.5.5v6a.5.5,0,0,1-.5.5H4.793a.5.5,0,0,0-.353.146L3,15.585V14.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5v-6A.5.5,0,0,1,1.5,7Zm-8-1A1.5,1.5,0,0,0,0,7.5v6A1.5,1.5,0,0,0,1.5,15H2v2.4a.25.25,0,0,0,.427.177L5,15H9.5A1.5,1.5,0,0,0,11,13.5v-6A1.5,1.5,0,0,0,9.5,6Z"
          fill={color}
        />
        <path
          id="Path_12976"
          data-name="Path 12976"
          d="M12,7.3A2.3,2.3,0,0,0,9.7,5H6V2.5A1.5,1.5,0,0,1,7.5,1h9A1.5,1.5,0,0,1,18,2.5v6A1.5,1.5,0,0,1,16.5,10H15v2.4a.25.25,0,0,1-.427.177L12,10Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const CasesIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.3"
      height="14"
      viewBox="0 0 14.3 14"
    >
      <g
        id="Decorative_icon"
        data-name="Decorative icon"
        transform="translate(0.15)"
      >
        <rect
          id="Placement_Area"
          data-name="Placement Area"
          width="14"
          height="14"
          fill="red"
          opacity="0"
        />
        <g id="Icon">
          <rect id="Canvas" width="14" height="14" fill="#747474" opacity="0" />
          <path
            id="Path_12989"
            data-name="Path 12989"
            d="M13.611,2H.389A.389.389,0,0,0,0,2.389V12.5a.389.389,0,0,0,.389.389H13.611A.389.389,0,0,0,14,12.5V2.389A.389.389,0,0,0,13.611,2Zm-.389.778v.586L7,8.121.778,3.363V2.778Zm0,1.564v6.183L9.178,7.434Zm-8.4,3.092L.778,10.526V4.342ZM.778,12.111V11.5L5.461,7.924l1.067.817a.778.778,0,0,0,.945,0l1.067-.817L13.222,11.5v.607Z"
            transform="translate(0 -0.444)"
            fill={color}
            stroke="#464646"
            strokeWidth="0.3"
          />
        </g>
      </g>
    </svg>
  );
};

export const HistoryIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      id="Decorative_icon"
      data-name="Decorative icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <rect
        id="Placement_Area"
        data-name="Placement Area"
        width="16"
        height="16"
        transform="translate(0)"
        fill="red"
        opacity="0"
      />
      <g id="Icon" transform="translate(0)">
        <rect
          id="Canvas"
          width="16"
          height="16"
          transform="translate(0)"
          fill={color}
          opacity="0"
        />
        <path
          id="Path_12982"
          data-name="Path 12982"
          d="M9.333,3H8.444A.444.444,0,0,0,8,3.444v4.7a.444.444,0,0,0,.13.314l2.492,2.492a.444.444,0,0,0,.628,0l.594-.594a.444.444,0,0,0,0-.628L9.778,7.667V3.444A.444.444,0,0,0,9.333,3Z"
          transform="translate(-0.889 -0.333)"
          fill={color}
        />
        <path
          id="Path_12983"
          data-name="Path 12983"
          d="M8,1A7.087,7.087,0,0,0,3.047,3.008,5.833,5.833,0,0,0,1.265,6.333H.222A.222.222,0,0,0,0,6.556a.218.218,0,0,0,.065.156L1.637,8.5a.222.222,0,0,0,.281,0L3.49,6.711a.218.218,0,0,0,.065-.156.222.222,0,0,0-.222-.222H2.208A5.2,5.2,0,0,1,3.759,3.558a6.222,6.222,0,1,1,0,9.1.222.222,0,0,0-.307,0l-.314.314a.225.225,0,0,0,0,.318l0,0A7.111,7.111,0,1,0,8,1Z"
          transform="translate(0 -0.111)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const SearchIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      id="Decorative_icon"
      data-name="Decorative icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <rect
        id="Placement_Area"
        data-name="Placement Area"
        width="16"
        height="16"
        fill={color}
        opacity="0"
      />
      <g id="Icon">
        <rect id="Canvas" width="16" height="16" fill="#464646" opacity="0" />
        <path
          id="Path_12998"
          data-name="Path 12998"
          d="M14.863,13.548l-3.455-3.454a5.7,5.7,0,1,0-1.314,1.314l3.454,3.454a.933.933,0,0,0,1.315-1.315ZM2.786,6.786a4,4,0,1,1,4,4,4,4,0,0,1-4-4Z"
          transform="translate(-0.119 -0.119)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const ContactsIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12.632"
      height="16"
      viewBox="0 0 12.632 16"
    >
      <path
        id="contact_page_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M186.316-850.51a1.422,1.422,0,0,0,1.044-.43,1.422,1.422,0,0,0,.43-1.044,1.422,1.422,0,0,0-.43-1.044,1.421,1.421,0,0,0-1.044-.43,1.421,1.421,0,0,0-1.044.43,1.422,1.422,0,0,0-.43,1.044,1.422,1.422,0,0,0,.43,1.044A1.422,1.422,0,0,0,186.316-850.51Zm-3.158,3.109h6.316v-.306a1.364,1.364,0,0,0-.241-.8,1.546,1.546,0,0,0-.661-.535,6,6,0,0,0-1.086-.346,5.5,5.5,0,0,0-1.17-.122,5.494,5.494,0,0,0-1.17.122,6,6,0,0,0-1.086.346,1.546,1.546,0,0,0-.661.535,1.364,1.364,0,0,0-.241.8Zm7.951,3.4h-9.587a1.469,1.469,0,0,1-1.08-.442,1.469,1.469,0,0,1-.442-1.08v-12.955a1.469,1.469,0,0,1,.442-1.08,1.469,1.469,0,0,1,1.08-.442h5.669a1.494,1.494,0,0,1,.585.118,1.507,1.507,0,0,1,.488.326l3.922,3.922a1.509,1.509,0,0,1,.325.488,1.492,1.492,0,0,1,.118.585v9.038a1.469,1.469,0,0,1-.442,1.08A1.469,1.469,0,0,1,191.109-844Zm0-1.263a.247.247,0,0,0,.178-.081.247.247,0,0,0,.081-.178v-9.13l-4.084-4.084h-5.762a.247.247,0,0,0-.178.081.247.247,0,0,0-.081.178v12.955a.247.247,0,0,0,.081.178.247.247,0,0,0,.178.081Zm-9.846,0v0Z"
        transform="translate(-180 860)"
        fill={color}
      />
    </svg>
  );
};

export const TemplatesIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12.632"
      height="16"
      viewBox="0 0 12.632 16"
    >
      <path
        id="description_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M183.789-847.158h5.053a.611.611,0,0,0,.45-.181.611.611,0,0,0,.181-.45.611.611,0,0,0-.181-.45.611.611,0,0,0-.45-.181h-5.053a.611.611,0,0,0-.45.181.611.611,0,0,0-.181.45.611.611,0,0,0,.181.45A.611.611,0,0,0,183.789-847.158Zm0-3.368h5.053a.611.611,0,0,0,.45-.181.611.611,0,0,0,.181-.45.611.611,0,0,0-.181-.45.611.611,0,0,0-.45-.181h-5.053a.611.611,0,0,0-.45.181.611.611,0,0,0-.181.45.611.611,0,0,0,.181.45A.611.611,0,0,0,183.789-850.526ZM181.522-844a1.469,1.469,0,0,1-1.08-.442,1.469,1.469,0,0,1-.442-1.08v-12.955a1.469,1.469,0,0,1,.442-1.08,1.469,1.469,0,0,1,1.08-.442h6.058a1.5,1.5,0,0,1,.585.118,1.511,1.511,0,0,1,.488.325l3.533,3.534a1.51,1.51,0,0,1,.325.488,1.494,1.494,0,0,1,.118.585v9.427a1.469,1.469,0,0,1-.442,1.08,1.469,1.469,0,0,1-1.08.442Zm6.057-11.709v-3.028h-6.057a.247.247,0,0,0-.178.081.247.247,0,0,0-.081.178v12.955a.247.247,0,0,0,.081.178.247.247,0,0,0,.178.081h9.587a.247.247,0,0,0,.178-.081.247.247,0,0,0,.081-.178v-9.425H188.34a.737.737,0,0,1-.543-.218A.737.737,0,0,1,187.579-855.709Zm-6.316-3.028v0Z"
        transform="translate(-180 860)"
        fill={color}
      />
    </svg>
  );
};

export const CustomResponsesIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12.217"
      viewBox="0 0 16 12.217"
    >
      <path
        id="prompt_suggestion_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24_1_"
        data-name="prompt_suggestion_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24 (1)"
        d="M153.593-732.043h-9.587a3.884,3.884,0,0,1-2.838-1.157A3.819,3.819,0,0,1,140-736.022a3.819,3.819,0,0,1,1.168-2.822A3.884,3.884,0,0,1,144.006-740a.7.7,0,0,1,.514.208.7.7,0,0,1,.208.514.7.7,0,0,1-.208.514.7.7,0,0,1-.514.207,2.493,2.493,0,0,0-1.814.735,2.427,2.427,0,0,0-.749,1.8,2.424,2.424,0,0,0,.749,1.8,2.494,2.494,0,0,0,1.814.735h9.587l-3.062-3.049a.7.7,0,0,1-.215-.5.666.666,0,0,1,.212-.516.7.7,0,0,1,.507-.2.673.673,0,0,1,.5.2l4.2,4.167a.847.847,0,0,1,.261.615.839.839,0,0,1-.261.612l-4.2,4.167a.657.657,0,0,1-.5.206.728.728,0,0,1-.5-.221.731.731,0,0,1-.217-.507.654.654,0,0,1,.217-.507Z"
        transform="translate(-140 740)"
        fill={color}
      />
    </svg>
  );
};

export const NotesIcon = ({ color }: { color: string }) => {
  return (
    <svg
      className="spectrum-Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.316"
      height="16"
      viewBox="0 0 14.316 16"
    >
      <path
        id="note_alt_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M141.522-884a1.467,1.467,0,0,1-1.076-.446,1.467,1.467,0,0,1-.446-1.076v-11.271a1.467,1.467,0,0,1,.446-1.076,1.467,1.467,0,0,1,1.076-.446h3.747a1.862,1.862,0,0,1,.612-1.193,1.816,1.816,0,0,1,1.276-.492,1.827,1.827,0,0,1,1.284.492,1.827,1.827,0,0,1,.6,1.193h3.747a1.467,1.467,0,0,1,1.076.446,1.467,1.467,0,0,1,.446,1.076v11.271a1.467,1.467,0,0,1-.446,1.076,1.467,1.467,0,0,1-1.076.446Zm0-1.263h11.271a.247.247,0,0,0,.178-.081.247.247,0,0,0,.081-.178v-11.271a.247.247,0,0,0-.081-.178.247.247,0,0,0-.178-.081H141.522a.247.247,0,0,0-.178.081.247.247,0,0,0-.081.178v11.271a.247.247,0,0,0,.081.178A.247.247,0,0,0,141.522-885.263Zm5.636-12.34a.613.613,0,0,0,.453-.179.613.613,0,0,0,.179-.453.614.614,0,0,0-.179-.453.614.614,0,0,0-.453-.179.614.614,0,0,0-.453.179.614.614,0,0,0-.179.453.613.613,0,0,0,.179.453A.613.613,0,0,0,147.158-897.6Zm-5.895,12.34v0Zm2.389-2.008h.63a.7.7,0,0,0,.29-.061.8.8,0,0,0,.243-.167l4.792-4.75-1.357-1.357-4.75,4.75a.764.764,0,0,0-.173.256.775.775,0,0,0-.055.29v.659a.365.365,0,0,0,.114.266A.365.365,0,0,0,143.652-887.271Zm6.479-5.5.787-.813a.4.4,0,0,0,.126-.295.4.4,0,0,0-.126-.295l-.741-.741a.4.4,0,0,0-.295-.126.4.4,0,0,0-.295.126l-.813.787Z"
        transform="translate(-140 900)"
        fill={color}
      />
    </svg>
  );
};

export const LikeOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M14.922,6.004l-3.275.005a10.73706,10.73706,0,0,0,.4-3.04A1.90165,1.90165,0,0,0,10.4605,1,1.6635,1.6635,0,0,0,8.82,2.582C8.5845,4.8595,6.1935,6.7135,4.936,7.2685A.495.495,0,0,0,4.5,7h-3a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V15h7.222a2.318,2.318,0,0,0,2.063-1.2115L16.277,8.15a1.5,1.5,0,0,0-1.355-2.146Zm.4525,1.712L13.366,13.394a.921.921,0,0,1-.871.616L5,14V8.3c1.4335-.5765,4.575-2.7625,4.82-5.7a.687.687,0,0,1,.6405-.6c.305,0,.5605.371.5865.969A7.52457,7.52457,0,0,1,10.174,7h4.75a.5.5,0,0,1,.4505.716Z"
      />
    </svg>
  );
};

export const LikeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <rect fill="#464646" height="9" rx="0.5" width="3" x="1" y="7" />
      <path
        fill="#464646"
        d="M15.4835,7H10.5A27.46988,27.46988,0,0,0,11,2.46C11,1.6295,10.484,1,10,1A.9195.9195,0,0,0,9,2,5.663,5.663,0,0,1,7.742,5.129,23.17516,23.17516,0,0,1,5,7.521V15s1.4045-.0165,7,0a1.973,1.973,0,0,0,1.8385-1.212l2.564-5.394A1,1,0,0,0,15.4835,7Z"
      />
    </svg>
  );
};
export const DislikeOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <path
        className="fill"
        d="M16.277,9.85,14.285,4.212A2.318,2.318,0,0,0,12.222,3H5a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,1,3v8a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,5,11v-.238c1.2725.587,3.5885,2.415,3.82,4.656A1.6635,1.6635,0,0,0,10.4605,17a1.9023,1.9023,0,0,0,1.5865-1.9685,10.73831,10.73831,0,0,0-.4-3.0405l3.275.005A1.5,1.5,0,0,0,16.277,9.85ZM14.9235,11h-4.75a7.52559,7.52559,0,0,1,.873,4.0315c-.026.6-.2815.966-.5865.9685a.687.687,0,0,1-.6405-.6c-.245-2.9365-3.3865-5.1225-4.82-5.7V4l7.4955-.01a.921.921,0,0,1,.871.616l2.0085,5.678a.5.5,0,0,1-.451.716Z"
      />
    </svg>
  );
};

export const DislikeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
      <rect fill="#464646" height="9" rx="0.5" width="3" x="1" y="3" />
      <path
        fill="#464646"
        d="M15.5385,10.945H10.555A31.9301,31.9301,0,0,1,11,15.54c0,.8305-.516,1.46-1,1.46a.9195.9195,0,0,1-1-1,5.663,5.663,0,0,0-1.258-3.129A23.17516,23.17516,0,0,0,5,10.479V3s1.4045.0165,7,0a1.973,1.973,0,0,1,1.8385,1.212l2.564,5.394a.931.931,0,0,1-.864,1.339Z"
      />
    </svg>
  );
};
export const RefreshIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect id="Canvas" fill={color} opacity="0" width="18" height="18" />
      <path
        className="fill"
        fill={color}
        d="M16.337,10H15.39a.6075.6075,0,0,0-.581.469A5.7235,5.7235,0,0,1,5.25,13.006l-.346-.3465L6.8815,10.682A.392.392,0,0,0,7,10.4a.4.4,0,0,0-.377-.4H1.25a.25.25,0,0,0-.25.25v5.375A.4.4,0,0,0,1.4,16a.3905.3905,0,0,0,.28-.118l1.8085-1.8085.178.1785a8.09048,8.09048,0,0,0,3.642,2.1655,7.715,7.715,0,0,0,9.4379-5.47434q.04733-.178.0861-.35816A.5.5,0,0,0,16.337,10Z"
      />
      <path
        className="fill"
        fill={color}
        d="M16.6,2a.3905.3905,0,0,0-.28.118L14.5095,3.9265l-.178-.1765a8.09048,8.09048,0,0,0-3.642-2.1655A7.715,7.715,0,0,0,1.25269,7.06072q-.04677.17612-.08519.35428A.5.5,0,0,0,1.663,8H2.61a.6075.6075,0,0,0,.581-.469A5.7235,5.7235,0,0,1,12.75,4.994l.346.3465L11.1185,7.318A.392.392,0,0,0,11,7.6a.4.4,0,0,0,.377.4H16.75A.25.25,0,0,0,17,7.75V2.377A.4.4,0,0,0,16.6,2Z"
      />
    </svg>
  );
};

export const PhoneDisabledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.726"
      height="48"
      viewBox="0 0 47.726 48"
    >
      <path
        id="phone_disabled_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M142.185-772.574l-12.972-12.972a47.921,47.921,0,0,1-12.507,7.734,34.373,34.373,0,0,1-13.31,2.748,2.646,2.646,0,0,1-2.117-.793,2.928,2.928,0,0,1-.684-1.982v-8.57a2.647,2.647,0,0,1,.633-1.772,2.915,2.915,0,0,1,1.634-.968l7.34-1.495a3.676,3.676,0,0,1,1.78.135,3.684,3.684,0,0,1,1.556.9l5.931,5.983q1.647-.93,3.556-2.249a40.05,40.05,0,0,0,3.322-2.534L98.368-816.391a1.913,1.913,0,0,1-.562-1.38,1.869,1.869,0,0,1,.562-1.406,1.9,1.9,0,0,1,1.393-.574,1.9,1.9,0,0,1,1.392.574L144.97-775.36a1.915,1.915,0,0,1,.562,1.38,1.869,1.869,0,0,1-.562,1.406,1.9,1.9,0,0,1-1.393.574A1.9,1.9,0,0,1,142.185-772.574Zm-26.494-9.261-4.666-4.718a.655.655,0,0,0-.242-.165.421.421,0,0,0-.267.013l-5.551,1.169a.546.546,0,0,0-.3.178.512.512,0,0,0-.1.33v5.561a.345.345,0,0,0,.1.254.345.345,0,0,0,.254.1,28.553,28.553,0,0,0,5.314-.811,29.227,29.227,0,0,0,5.462-1.914Zm19.956-10.354-2.785-2.786a32.937,32.937,0,0,0,2.155-2.869,33.509,33.509,0,0,0,1.835-3l-6.1-5.943a2.922,2.922,0,0,1-.816-1.5,3.874,3.874,0,0,1-.054-1.667l1.515-7.777a2.915,2.915,0,0,1,.968-1.634,2.647,2.647,0,0,1,1.772-.633h8.621a2.668,2.668,0,0,1,1.982.806,2.734,2.734,0,0,1,.793,2,33.851,33.851,0,0,1-2.674,13.043A47.012,47.012,0,0,1,135.648-792.19Zm3.04-12.408a33.662,33.662,0,0,0,1.909-5.577,33.842,33.842,0,0,0,.887-5.5.345.345,0,0,0-.1-.254.345.345,0,0,0-.254-.1h-5.612a.512.512,0,0,0-.33.1.545.545,0,0,0-.178.3l-1.189,5.907a.576.576,0,0,0-.013.318.592.592,0,0,0,.165.292l4.717,4.514ZM138.687-804.6ZM115.691-781.836Z"
        transform="translate(-97.806 820)"
        fill="#747474"
      />
    </svg>
  );
};

export const ForwardPhoneCallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.916"
      height="18"
      viewBox="0 0 17.916 18"
    >
      <path
        id="phone_forwarded_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M155.355-846.248h-4.83a.736.736,0,0,1-.542-.218.735.735,0,0,1-.218-.542.736.736,0,0,1,.218-.542.736.736,0,0,1,.542-.218h4.83l-1.31-1.31a.753.753,0,0,1-.223-.521.73.73,0,0,1,.208-.547.781.781,0,0,1,.55-.244.726.726,0,0,1,.55.228l2.512,2.543a.879.879,0,0,1,.275.641.879.879,0,0,1-.275.641l-2.528,2.5a.77.77,0,0,1-.529.215.748.748,0,0,1-.539-.215.774.774,0,0,1-.244-.542.718.718,0,0,1,.228-.542Zm.8,13.858a13.663,13.663,0,0,1-5.757-1.332,18.392,18.392,0,0,1-5.318-3.759,18.5,18.5,0,0,1-3.749-5.313A13.629,13.629,0,0,1,140-848.546a1.049,1.049,0,0,1,.3-.765,1.023,1.023,0,0,1,.76-.309h3.306a1.012,1.012,0,0,1,.677.251,1.1,1.1,0,0,1,.373.619l.581,2.982a1.706,1.706,0,0,1-.025.713,1.062,1.062,0,0,1-.308.5l-2.341,2.279a14.049,14.049,0,0,0,1.291,1.958,19.276,19.276,0,0,0,1.572,1.763,17.452,17.452,0,0,0,1.774,1.55,17.836,17.836,0,0,0,2.029,1.331l2.274-2.294a1.288,1.288,0,0,1,.576-.347,1.654,1.654,0,0,1,.7-.049l2.814.573a1.192,1.192,0,0,1,.627.392,1,1,0,0,1,.243.659v3.286a1.023,1.023,0,0,1-.309.76A1.048,1.048,0,0,1,156.156-832.39Zm-13.548-11.324,1.809-1.731a.181.181,0,0,0,.063-.107.237.237,0,0,0,0-.127l-.441-2.265a.209.209,0,0,0-.068-.117.2.2,0,0,0-.127-.039h-2.167a.132.132,0,0,0-.1.039.133.133,0,0,0-.039.1,12.939,12.939,0,0,0,.34,2.111A12.887,12.887,0,0,0,142.608-843.714Zm8.818,8.759a10.425,10.425,0,0,0,2.1.718,11.944,11.944,0,0,0,2.046.3.132.132,0,0,0,.1-.039.132.132,0,0,0,.039-.1v-2.132a.2.2,0,0,0-.039-.127.209.209,0,0,0-.117-.068l-2.128-.433a.16.16,0,0,0-.1,0,.25.25,0,0,0-.093.063ZM142.608-843.714ZM151.426-834.955Z"
        transform="translate(-140 850.39)"
        fill="#464646"
      />
    </svg>
  );
};

export const MergeCallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.961"
      height="18"
      viewBox="0 0 13.961 18"
    >
      <path
        id="merge_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M233.971-793.374l-5.6,5.6a.776.776,0,0,1-.562.232.781.781,0,0,1-.579-.243.78.78,0,0,1-.229-.575.781.781,0,0,1,.229-.576l4.663-4.68a4.356,4.356,0,0,0,.959-1.359,4.153,4.153,0,0,0,.293-1.628v-6.193l-1.479,1.48a.764.764,0,0,1-.562.229.792.792,0,0,1-.579-.246.781.781,0,0,1-.229-.575.781.781,0,0,1,.229-.576l2.749-2.76a.956.956,0,0,1,.7-.3.956.956,0,0,1,.7.3l2.759,2.76a.794.794,0,0,1,.238.562.763.763,0,0,1-.238.579.78.78,0,0,1-.575.229.781.781,0,0,1-.576-.229l-1.479-1.452v6.193a4.1,4.1,0,0,0,.3,1.638,4.472,4.472,0,0,0,.97,1.369l4.652,4.669a.8.8,0,0,1,.237.562.762.762,0,0,1-.237.579.781.781,0,0,1-.576.229.78.78,0,0,1-.575-.229Z"
        transform="translate(-227 805.54)"
        fill="#464646"
      />
    </svg>
  );
};

export const AddCallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="add_call_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
        d="M153.235-815.235h-2.382a.769.769,0,0,1-.566-.228.769.769,0,0,1-.228-.566.768.768,0,0,1,.228-.566.769.769,0,0,1,.566-.228h2.382v-2.382a.768.768,0,0,1,.228-.566.769.769,0,0,1,.566-.228.767.767,0,0,1,.566.228.768.768,0,0,1,.228.566v2.382h2.382a.768.768,0,0,1,.566.228.769.769,0,0,1,.228.566.767.767,0,0,1-.228.566.768.768,0,0,1-.566.228h-2.382v2.382a.769.769,0,0,1-.228.566.769.769,0,0,1-.566.228.768.768,0,0,1-.566-.228.769.769,0,0,1-.228-.566ZM156.878-802a14.275,14.275,0,0,1-6.014-1.392,19.148,19.148,0,0,1-5.551-3.927,19.282,19.282,0,0,1-3.922-5.551A14.238,14.238,0,0,1,140-818.878a1.093,1.093,0,0,1,.318-.8,1.073,1.073,0,0,1,.794-.32h3.453a1.057,1.057,0,0,1,.708.262,1.15,1.15,0,0,1,.39.646l.607,3.115a1.783,1.783,0,0,1-.026.745,1.108,1.108,0,0,1-.322.523l-2.445,2.38a14.684,14.684,0,0,0,1.349,2.045,20.143,20.143,0,0,0,1.642,1.841,18.241,18.241,0,0,0,1.853,1.619,18.635,18.635,0,0,0,2.12,1.391l2.376-2.4a1.346,1.346,0,0,1,.6-.363,1.729,1.729,0,0,1,.734-.051l2.94.6a1.244,1.244,0,0,1,.655.409,1.044,1.044,0,0,1,.254.688v3.433a1.072,1.072,0,0,1-.321.794A1.093,1.093,0,0,1,156.878-802Zm-14.154-11.83,1.89-1.808a.189.189,0,0,0,.066-.112.247.247,0,0,0-.005-.132l-.46-2.366a.219.219,0,0,0-.071-.122.206.206,0,0,0-.132-.041h-2.264a.138.138,0,0,0-.1.041.139.139,0,0,0-.041.1,13.52,13.52,0,0,0,.355,2.205A13.466,13.466,0,0,0,142.724-813.83Zm9.212,9.151a10.9,10.9,0,0,0,2.2.75,12.462,12.462,0,0,0,2.137.308.138.138,0,0,0,.1-.041.138.138,0,0,0,.041-.1v-2.227a.205.205,0,0,0-.041-.132.219.219,0,0,0-.122-.071l-2.224-.452a.167.167,0,0,0-.107-.005.263.263,0,0,0-.1.066ZM142.724-813.83ZM151.936-804.68Z"
        transform="translate(-140 820)"
        fill="#464646"
      />
    </svg>
  );
};

export const BackspaceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16.174"
      viewBox="0 0 20 16.174"
    >
      <path
        id="backspace_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24_1_"
        data-name="backspace_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24 (1)"
        d="M129.806-770.777l2.235,2.236a.782.782,0,0,0,.563.229.762.762,0,0,0,.573-.229.775.775,0,0,0,.234-.568.774.774,0,0,0-.234-.568l-2.235-2.235,2.235-2.235a.781.781,0,0,0,.229-.563.763.763,0,0,0-.229-.573.773.773,0,0,0-.568-.234.774.774,0,0,0-.568.234l-2.235,2.236-2.236-2.236a.782.782,0,0,0-.563-.229.762.762,0,0,0-.573.229.774.774,0,0,0-.234.568.774.774,0,0,0,.234.568l2.235,2.235-2.235,2.235a.781.781,0,0,0-.229.563.763.763,0,0,0,.229.573.774.774,0,0,0,.568.234.773.773,0,0,0,.568-.234Zm-5.112,6.951a1.977,1.977,0,0,1-.877-.2,1.817,1.817,0,0,1-.682-.566l-4.655-6.138a1.9,1.9,0,0,1-.4-1.18,1.9,1.9,0,0,1,.4-1.18l4.655-6.138a1.816,1.816,0,0,1,.682-.566,1.977,1.977,0,0,1,.877-.2h11.436a1.878,1.878,0,0,1,1.378.571,1.878,1.878,0,0,1,.571,1.378v12.276a1.879,1.879,0,0,1-.571,1.378,1.878,1.878,0,0,1-1.378.571Zm0-1.617h11.436a.323.323,0,0,0,.239-.093.323.323,0,0,0,.093-.239v-12.276a.323.323,0,0,0-.093-.239.323.323,0,0,0-.239-.093H124.695a.356.356,0,0,0-.15.031.342.342,0,0,0-.119.094l-4.655,6.138a.33.33,0,0,0-.073.207.33.33,0,0,0,.073.207l4.655,6.138a.341.341,0,0,0,.119.094A.355.355,0,0,0,124.695-765.443ZM128.075-771.913Z"
        transform="translate(-118.08 780)"
        fill="#505050"
      />
    </svg>
  );
};

export const OnCallIcon = () => {
  return (
    <svg
      id="On_Call_Icon"
      data-name="On_Call Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <circle
        id="Ellipse_98"
        data-name="Ellipse 98"
        cx="10"
        cy="10"
        r="10"
        fill="#007a4d"
      />
      <g id="Icon" transform="translate(-10 -3)">
        <rect
          id="Canvas"
          width="12"
          height="12"
          transform="translate(14 7)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="call_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24_1_"
          data-name="call_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24 (1)"
          d="M150-809.333a8.461,8.461,0,0,1-3.564-.825,11.348,11.348,0,0,1-3.289-2.327,11.427,11.427,0,0,1-2.324-3.289,8.438,8.438,0,0,1-.825-3.561.648.648,0,0,1,.188-.475.636.636,0,0,1,.471-.19h2.046a.627.627,0,0,1,.419.155.681.681,0,0,1,.231.383l.36,1.846a1.057,1.057,0,0,1-.016.442.658.658,0,0,1-.191.31l-1.449,1.411a8.7,8.7,0,0,0,.8,1.212,11.953,11.953,0,0,0,.973,1.091,10.831,10.831,0,0,0,1.1.959,11.046,11.046,0,0,0,1.256.824l1.408-1.42a.8.8,0,0,1,.357-.215,1.023,1.023,0,0,1,.435-.03l1.742.355a.736.736,0,0,1,.388.242.618.618,0,0,1,.15.408v2.034a.635.635,0,0,1-.19.471A.647.647,0,0,1,150-809.333Zm-8.387-7.01,1.12-1.072a.112.112,0,0,0,.039-.066.147.147,0,0,0,0-.078l-.273-1.4a.13.13,0,0,0-.042-.072.121.121,0,0,0-.078-.024h-1.342a.082.082,0,0,0-.06.024.082.082,0,0,0-.024.06,8.006,8.006,0,0,0,.211,1.307A7.986,7.986,0,0,0,141.614-816.344Zm5.459,5.423a6.464,6.464,0,0,0,1.3.445,7.375,7.375,0,0,0,1.266.183.081.081,0,0,0,.06-.024.082.082,0,0,0,.024-.06v-1.32a.122.122,0,0,0-.024-.079.131.131,0,0,0-.072-.042l-1.318-.268a.1.1,0,0,0-.063,0,.155.155,0,0,0-.057.039ZM141.614-816.344ZM147.073-810.921Z"
          transform="translate(-125.333 827.667)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};

export const RefreshTimerIcon = () => {
  return (
    <svg
      fill="#000000"
      height="18"
      width="18"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enable-background="new 0 0 512 512"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path d="M256,0C179.9,0,111.7,33.4,64.9,86.2L0,21.3V192h170.7l-60.2-60.2C145.6,90.5,197.5,64,256,64c106,0,192,85.9,192,192 c0,106.1-86,192-192,192c-53,0-101-21.5-135.8-56.2L75,437c46.4,46.3,110.4,75,181,75c141.4,0,256-114.6,256-256S397.4,0,256,0z M234.7,106.7v170.7h128v-42.7h-85.3v-128H234.7z" />{" "}
      </g>
    </svg>
  );
};

export const TimerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="Group_172161"
        data-name="Group 172161"
        transform="translate(-617 -173)"
      >
        <g
          id="Rectangle_137361"
          data-name="Rectangle 137361"
          transform="translate(617 173)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="18" height="18" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
        </g>
        <path
          id="timer_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
          d="M145.726-908.667a.644.644,0,0,1-.475-.192.645.645,0,0,1-.192-.475.644.644,0,0,1,.192-.475.645.645,0,0,1,.475-.192h3.658a.644.644,0,0,1,.475.192.645.645,0,0,1,.192.475.644.644,0,0,1-.192.475.645.645,0,0,1-.475.192Zm1.829,9.829a.644.644,0,0,0,.475-.192.645.645,0,0,0,.192-.475v-3.658a.644.644,0,0,0-.192-.475.645.645,0,0,0-.475-.192.644.644,0,0,0-.475.192.645.645,0,0,0-.192.475v3.658a.644.644,0,0,0,.192.475A.645.645,0,0,0,147.556-898.838Zm0,6.838a7.308,7.308,0,0,1-2.931-.595,7.666,7.666,0,0,1-2.405-1.624,7.667,7.667,0,0,1-1.624-2.405,7.309,7.309,0,0,1-.595-2.931,7.309,7.309,0,0,1,.595-2.931,7.666,7.666,0,0,1,1.624-2.405,7.667,7.667,0,0,1,2.405-1.624,7.309,7.309,0,0,1,2.931-.595,7.417,7.417,0,0,1,2.572.453,8.184,8.184,0,0,1,2.3,1.3l.639-.639a.644.644,0,0,1,.464-.189A.629.629,0,0,1,154-906a.638.638,0,0,1,.193.468.638.638,0,0,1-.193.468l-.639.639a8.186,8.186,0,0,1,1.3,2.3,7.417,7.417,0,0,1,.453,2.572,7.309,7.309,0,0,1-.595,2.931,7.667,7.667,0,0,1-1.624,2.405,7.666,7.666,0,0,1-2.405,1.624A7.308,7.308,0,0,1,147.556-892Zm0-1.333a6,6,0,0,0,4.4-1.822,6,6,0,0,0,1.822-4.4,6,6,0,0,0-1.822-4.4,6,6,0,0,0-4.4-1.822,6,6,0,0,0-4.4,1.822,6,6,0,0,0-1.822,4.4,6,6,0,0,0,1.822,4.4A6,6,0,0,0,147.556-893.333ZM147.556-899.556Z"
          transform="translate(478.444 1083)"
          fill="#464646"
        />
      </g>
    </svg>
  );
};

export const CallOutwardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="Call_Outward" transform="translate(344 255) rotate(180)">
        <g
          id="Rectangle_137279"
          data-name="Rectangle 137279"
          transform="translate(328 239)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="16" height="16" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
        </g>
        <path
          id="call_made_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24"
          d="M187.353-757.2l-8.974,8.974a.781.781,0,0,1-.576.226.781.781,0,0,1-.576-.226A.781.781,0,0,1,177-748.8a.781.781,0,0,1,.226-.576l8.974-8.974H181.59a.8.8,0,0,1-.587-.237.8.8,0,0,1-.237-.587.8.8,0,0,1,.237-.587.8.8,0,0,1,.587-.237h6.587a.8.8,0,0,1,.587.237.8.8,0,0,1,.237.587v6.587a.8.8,0,0,1-.237.587.8.8,0,0,1-.587.237.8.8,0,0,1-.587-.237.8.8,0,0,1-.237-.587Z"
          transform="translate(519 -507) rotate(180)"
          fill="#464646"
        />
      </g>
    </svg>
  );
};

export const CallInwardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="Call_Inward" transform="translate(-328 -239)">
        <g
          id="Rectangle_137279"
          data-name="Rectangle 137279"
          transform="translate(328 239)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="16" height="16" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
        </g>
        <path
          id="call_made_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24"
          d="M187.353-757.2l-8.974,8.974a.781.781,0,0,1-.576.226.781.781,0,0,1-.576-.226A.781.781,0,0,1,177-748.8a.781.781,0,0,1,.226-.576l8.974-8.974H181.59a.8.8,0,0,1-.587-.237.8.8,0,0,1-.237-.587.8.8,0,0,1,.237-.587.8.8,0,0,1,.587-.237h6.587a.8.8,0,0,1,.587.237.8.8,0,0,1,.237.587v6.587a.8.8,0,0,1-.237.587.8.8,0,0,1-.587.237.8.8,0,0,1-.587-.237.8.8,0,0,1-.237-.587Z"
          transform="translate(519 -507) rotate(180)"
          fill="#464646"
        />
      </g>
    </svg>
  );
};

export const BeingMonitoredIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Call_Monitor"
        data-name="Call Monitor"
        transform="translate(3160 20528)"
      >
        <g
          id="Rectangle_137364"
          data-name="Rectangle 137364"
          transform="translate(-3160 -20528)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Group_172189"
          data-name="Group 172189"
          transform="translate(-784 -2102.906)"
        >
          <path
            id="multimodal_hand_eye_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
            d="M399.319-851.658a7.4,7.4,0,0,1-3.634-.927,7.457,7.457,0,0,1-2.725-2.574,1.206,1.206,0,0,1-.146-.318,1.3,1.3,0,0,1-.044-.352,1.3,1.3,0,0,1,.044-.352,1.184,1.184,0,0,1,.147-.318,7.308,7.308,0,0,1,2.714-2.574,7.426,7.426,0,0,1,3.639-.927,7.4,7.4,0,0,1,3.634.927,7.455,7.455,0,0,1,2.725,2.574,1.189,1.189,0,0,1,.146.318,1.3,1.3,0,0,1,.044.352,1.3,1.3,0,0,1-.044.352,1.182,1.182,0,0,1-.147.318,7.311,7.311,0,0,1-2.714,2.574A7.427,7.427,0,0,1,399.319-851.658Zm0-1.572a5.87,5.87,0,0,0,2.782-.691,6.112,6.112,0,0,0,2.166-1.908,6.116,6.116,0,0,0-2.165-1.908,5.86,5.86,0,0,0-2.781-.691,5.869,5.869,0,0,0-2.782.691,6.112,6.112,0,0,0-2.166,1.908,6.116,6.116,0,0,0,2.165,1.908,5.862,5.862,0,0,0,2.781.691Zm0-1.29a1.265,1.265,0,0,1-.928-.382,1.26,1.26,0,0,1-.383-.927,1.266,1.266,0,0,1,.382-.928,1.26,1.26,0,0,1,.927-.383,1.266,1.266,0,0,1,.928.382,1.26,1.26,0,0,1,.383.927,1.265,1.265,0,0,1-.382.928A1.259,1.259,0,0,1,399.318-854.519Z"
            transform="translate(-2758.864 -17562)"
            fill="#464646"
          />
          <path
            id="perm_phone_msg_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24"
            d="M156.7-802.187a14.126,14.126,0,0,1-5.951-1.377,18.949,18.949,0,0,1-5.493-3.886,19.082,19.082,0,0,1-3.881-5.493A14.09,14.09,0,0,1,140-818.89a1.081,1.081,0,0,1,.314-.793A1.061,1.061,0,0,1,141.1-820h3.417a1.046,1.046,0,0,1,.7.259,1.138,1.138,0,0,1,.386.64l.6,3.083a1.765,1.765,0,0,1-.026.737,1.1,1.1,0,0,1-.319.518l-2.42,2.356a14.526,14.526,0,0,0,1.335,2.024,19.932,19.932,0,0,0,1.625,1.822,18.05,18.05,0,0,0,1.834,1.6,18.428,18.428,0,0,0,2.1,1.376l2.351-2.372a1.333,1.333,0,0,1,.6-.359,1.713,1.713,0,0,1,.726-.05l2.91.593a1.231,1.231,0,0,1,.648.4,1.033,1.033,0,0,1,.251.681v3.4a1.061,1.061,0,0,1-.317.786A1.081,1.081,0,0,1,156.7-802.187ZM142.7-813.894l1.87-1.789a.187.187,0,0,0,.065-.111.245.245,0,0,0,0-.131l-.456-2.342a.216.216,0,0,0-.07-.121.2.2,0,0,0-.131-.04h-2.241a.137.137,0,0,0-.1.04.137.137,0,0,0-.04.1,13.381,13.381,0,0,0,.352,2.182A13.33,13.33,0,0,0,142.7-813.894Zm9.116,9.055a10.778,10.778,0,0,0,2.173.743,12.345,12.345,0,0,0,2.115.305.137.137,0,0,0,.1-.04.137.137,0,0,0,.04-.1v-2.2a.2.2,0,0,0-.04-.131.217.217,0,0,0-.121-.07l-2.2-.447a.166.166,0,0,0-.106-.005.261.261,0,0,0-.1.065ZM142.7-813.894ZM151.812-804.839Z"
            transform="translate(-2515 -17602)"
            fill="#464646"
          />
        </g>
      </g>
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      id="Action_button"
      data-name="Action button"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 32 32"
    >
      <rect
        id="Placement_Area"
        data-name="Placement Area"
        width="24"
        height="24"
        fill="red"
        opacity="0"
      />
      <g
        id="Background"
        fill="rgba(255,255,255,0)"
        stroke="rgba(255,255,255,0)"
        strokeWidth="1"
      >
        <rect width="32" height="32" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="none" />
      </g>
      <g id="Icon">
        <rect
          id="Canvas"
          width="18"
          height="18"
          transform="translate(7 7)"
          fill="#222"
          opacity="0"
        />
        <path
          id="Path_13229"
          data-name="Path 13229"
          d="M13.243,3.343,9,7.586,4.758,3.343a.5.5,0,0,0-.707,0l-.707.707a.5.5,0,0,0,0,.707L7.586,9,3.343,13.243a.5.5,0,0,0,0,.707l.707.707a.5.5,0,0,0,.707,0L9,10.414l4.243,4.243a.5.5,0,0,0,.707,0l.707-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.242a.5.5,0,0,0,0-.707l-.707-.707a.5.5,0,0,0-.707,0Z"
          transform="translate(7 7)"
          fill="#222"
        />
      </g>
    </svg>
  );
};

export const BellIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path fill="#464646" d="M9,18a2.203,2.203,0,0,0,2-2H7A2.203,2.203,0,0,0,9,18Z" />
    <path fill="#464646" d="M13.5715,5.6925c0-1.7185-1.603-2.4455-3.5715-2.634V1.5A.5395.5395,0,0,0,9.4285,1h-.857A.5395.5395,0,0,0,8,1.5V3.0585c-1.9685.1885-3.5715.9155-3.5715,2.634C4.4285,13.4,2,13.0555,2,14.077V15H16v-.923C16,13,13.5715,13.4,13.5715,5.6925Z" />
  </svg>
}

export const ConnectIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="170px" height="143px" viewBox="0 0 47 42" version="1.1" transform="scale(0.8)" style={{height: '25px', width: '25px'}}>
    <g id="Connect-Colors-V1" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g id="Metrics-Navigation-Copy-26" transform="translate(-9.000000, -10.000000)">
        <g id="Header-Copy-6"><g id="Page-1-Copy-3" transform="translate(9.000000, 10.000000)">
          <path d="M39.5085902,13.257345 L39.5228232,13.1638025 C39.0806515,6.653621 33.1170263,5.52638639 30.4507118,9.12068687 L30.4507118,9.12163175 C29.7447552,7.13078234 28.6734851,5.29961664 27.2188729,3.84640051 C24.0724321,0.702804901 19.2891967,-0.971511728 14.0552498,1.23571381 C9.97891983,2.95443951 7.12662752,7.77140688 7.12662752,11.9553087 C7.12662752,12.447588 7.15604237,12.9323084 7.21297436,13.40758 L7.21297436,13.4047453 C4.23258508,14.164424 0.875495858,16.4255073 0.875495858,21.5334959 C0.875495858,21.6232589 0.876444725,21.7130219 0.878342457,21.8008952 C0.986513224,26.2635348 4.84175729,29.9457086 9.32420203,29.950433 L18.5661605,29.950433 L18.5661605,41.1991573 L30.7021614,29.950433 L38.4610417,29.950433 C39.4507093,29.9513778 40.4356326,29.7964185 41.3721637,29.477996 C43.6532385,28.7022545 46.9998902,26.6962871 46.9998902,21.6043614 C46.9998902,15.6280337 42.2204503,13.7212781 39.5085902,13.257345 Z" id="Stroke-3" fill="#00ABBA">
          </path>
          <path d="M18.6412158,15.521074 L13.6444855,19.0407294" id="Stroke-11" stroke="#26303B" strokeWidth="2" strokeLinecap="round">
          </path>
          <path d="M24.4460007,21.6205188 L21.6003505,16.2300129" id="Stroke-13" stroke="#26303B" strokeWidth="2" strokeLinecap="round">
          </path>
          <path d="M32.399968,18.8097078 L27.4041865,22.3284183" id="Stroke-15" stroke="#26303B" strokeWidth="2" strokeLinecap="round">
          </path>
          <path d="M34.8235625,15.2443204 C33.5824453,14.94763 32.3337371,15.7091984 32.0357931,16.9450935 C31.737849,18.1819335 32.5026353,19.4244427 33.7447014,19.7211332 C34.9858186,20.0178236 36.2335779,19.2562552 36.531522,18.0203601 C36.829466,16.7835201 36.0646797,15.5410108 34.8235625,15.2443204 Z" id="Stroke-17" stroke="#26303B" strokeWidth="2" stroke-linecap="round">
          </path>
          <path d="M26.0599276,21.4170874 C24.8188103,21.120397 23.5701022,21.8819654 23.2721582,23.1178605 C22.9742141,24.3547005 23.7390004,25.5972097 24.9810665,25.8939002 C26.2221837,26.1905906 27.469943,25.4290222 27.767887,24.1931271 C28.0658311,22.9562871 27.3010448,21.7137778 26.0599276,21.4170874 Z" id="Stroke-19" stroke="#26303B" strokeWidth="2" stroke-linecap="round"></path><path d="M21.0648103,11.9556867 C19.8236931,11.6589962 18.574985,12.4205646 18.2770409,13.6564598 C17.9790969,14.8932997 18.7438832,16.135809 19.9859492,16.4324994 C21.2270665,16.7291898 22.4748257,15.9676214 22.7727698,14.7317263 C23.0707138,13.4948863 22.3059275,12.2523771 21.0648103,11.9556867 Z" id="Stroke-21" stroke="#26303B" strokeWidth="2" stroke-linecap="round">
          </path>
          <path d="M12.3010805,18.1284536 C11.0599633,17.8317632 9.81125514,18.5933316 9.5133111,19.8292267 C9.21536706,21.0660667 9.98015336,22.308576 11.2222194,22.6052664 C12.4633367,22.9019568 13.7110959,22.1403884 14.00904,20.9044933 C14.306984,19.6676533 13.5421977,18.4251441 12.3010805,18.1284536 Z" id="Stroke-23" stroke="#26303B" strokeWidth="2" stroke-linecap="round">
          </path>
        </g>
      </g>
    </g>
  </g>
  </svg>
}