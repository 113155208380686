// import IncomingCallIcon from "../assets/callIncomingIcon.svg";
import OutgoingCallIcon from "../assets/callOutgoingIcon.svg";
import MissedCallIcon from "../assets/missedCallIcon.svg";
import OutgoingMissedIcon from "../assets/outboundMissedIcon.svg";
import EyeIcon from "../assets/eye-icon.svg";
/**
 *Parses timeString into date format
 * @param {*} date
 */
export function getLocaleTimeString(date: any) {
  if (!date) {
    date = new Date();
  }
  date = new Date(date);
  var now = new Date();
  let dateDiff = now.getTime() - date.getTime();
  let dateString = "";

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  if (day.toString().length === 1) {
    day = "0" + day;
  }
  if (hour.toString().length === 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length === 1) {
    minute = "0" + minute;
  }
  if (second.toString().length === 1) {
    second = "0" + second;
  }
  if (dateDiff > 24 * 60 * 60 * 1000) {
    dateString =
      year + "/" + month + "/" + day + " " + hour + ":" + minute + ":" + second;
  } else {
    if (dateDiff >= 1000 * 60 * 60) {
      let hoursAgo = Math.floor(dateDiff / (1000 * 60 * 60));
      dateString = hoursAgo === 1 ? " AN HOUR AGO" : hoursAgo + " HOURS AGO";
    } else if (dateDiff >= 1000 * 60) {
      let minutesAgo = Math.floor(dateDiff / (1000 * 60));
      dateString =
        minutesAgo === 1 ? " A MINUTE AGO" : minutesAgo + " MINUTES AGO";
    } else if (dateDiff >= 1000) {
      let secondsAgo = Math.floor(dateDiff / 1000);
      dateString =
        secondsAgo === 1 ? " A SECOND AGO" : secondsAgo + " SECONDS AGO";
    } else {
      dateString = "NOW";
    }
  }
  return dateString;
}

/**
 * Parses seconds duration  to return time string
 * @param {*seconds} seconds
 * @returns Time String like: 00:00:00
 */
export function getLocaleTimeDuration(seconds) {
  let dStr = "";
  let h = Math.floor(seconds / (60 * 60));
  let s = Math.floor((seconds % (60 * 60)) % 60);
  let m = Math.floor((seconds % (60 * 60)) / 60);
  if (h > 0) {
    dStr += h + " Hr" + (h > 1 ? "s " : " ");
  }
  if (m > 0) {
    dStr += m + " Min" + (m > 1 ? "s " : " ");
  }
  if (s > 0) {
    dStr += s + " Sec" + (s > 1 ? "s " : " ");
  }
  return dStr;
}

/**
 *
 * @param {*} secs
 */
export function getLocaleTimeDelimited(secs) {
  let mins = 0,
    hrs = 0;
  secs = Math.floor(secs);
  if (secs >= 60) {
    mins = Math.floor(secs / 60);
    secs = secs % 60;
  }

  if (mins >= 60) {
    hrs = Math.floor(mins / 60);
    mins = mins % 60;
  }
  return (
    hrs.toString().padStart(2, 0) +
    ":" +
    mins.toString().padStart(2, 0) +
    ":" +
    secs.toString().padStart(2, 0)
  );
}

/**
 * Select Element on Double Click
 * @param {*} e
 */
export function selectOnDoubleClick(e) {
  if (document.selection) {
    const range = document.body.createTextRange();
    range.moveToElementText(e.target);
    range.select();
  } else {
    // For other Browsers
    const range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

/**
 * @param function
 */

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const makeSearcher = function (terms) {
  var regex = new RegExp(terms.join("|"));
  return function (str) {
    return regex.test(str);
  };
};

export const canMakeCall = (medialList: string) => {
  if (medialList && medialList.length === 0) {
    return false;
  }
  return true;
};

export const createPromiseForFetch = (fetchUrl: string, timeout: number) => {
  return new Promise((resolve, reject) => {
    try {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
      fetch(fetchUrl, {
        signal: controller.signal,
      })
        .then(() => {
          clearTimeout(id);
          resolve("ok");
        })
        .catch(() => {
          clearTimeout(id);
          // reject('error');
          resolve("ok");
        });
    } catch (error) {
      reject("error");
    }
  });
};

// Function to merge two arrays with unique elements based on a key
export function mergeUnique(arr1, arr2, key) {
  const unique = new Map(arr1.map((item) => [item[key], item]));

  arr2.forEach((item) => {
    if (!unique.has(item[key])) {
      unique.set(item[key], item);
    }
  });

  return Array.from(unique.values());
}

// ccp function
export function formatAttributes(attributes: any) {
  let attributesObj: any = {};
  for (const key in attributes) {
    const val = attributes[key];
    if (val.name) attributesObj[val.name] = val.value;
  }
  return attributesObj;
}

export function formatCaseAttributes(attributes: any): {
  [key: string]: string;
} {
  let attributesObj: any = {};
  for (const key in attributes) {
    attributesObj[key] = attributes[key].value;
  }
  return attributesObj;
}
export const checkMicrophonePermission = () => {
  window.navigator.permissions
    .query({ name: "microphone" as PermissionName })
    .then((result) => {
      if (result.state === "granted") {
        //this.window.close();
        console.log("AIC ccp window: Microphone permission granted");
      } else {
        console.log(
          "AIC ccp window: Microphone permission denied! Ask for permission"
        );
      }
    });
};

export const sendErrorNotification = (title: string, message: string) => {
  return {
    title: title,
    message: message,
    variant: "negative",
  };
};

export function beforeUnloadListener(event: any) {
  event.preventDefault();
  event.returnValue = "Are you sure you want to exit?";
}

export const getCallIcon = (callerList: any) => {
  const { self } = callerList;
  //Hide for now as incoming is not supported for Production
  // if (self.direction === "INBOUND" && !self?.missed) {
  //   return IncomingCallIcon;
  // }
  if (self.direction === "INBOUND" && !self?.missed) {
    return EyeIcon;
  }
  if (self.direction === "OUTBOUND" && self?.missed) {
    return OutgoingMissedIcon;
  } else if (self.direction === "OUTBOUND") {
    return OutgoingCallIcon;
  } else if (self?.missed === true) {
    return MissedCallIcon;
  } else {
    return "";
  }
};

export const formatTime = (connectedTime: number, endTime: number) => {
  const connectedDate = new Date(connectedTime);
  const endDate = new Date(endTime);

  // Check for invalid dates
  if (isNaN(connectedDate.getTime()) || isNaN(endDate.getTime())) {
    return {
      formattedConnectedTime: "Invalid Time",
      formattedDifference: "Invalid Time",
    };
  }

  // Helper function to format the date
  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  // Check if the connectedTime is today
  const today = new Date();
  const isToday = connectedDate.toDateString() === today.toDateString();

  // Format connectedTime
  let formattedConnectedTime;
  if (isToday) {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    formattedConnectedTime = `Today ${connectedDate.toLocaleString("en-US", options)}`;
  } else {
    formattedConnectedTime = formatDate(connectedDate);
  }

  // Calculate difference in seconds
  const differenceInSeconds = Math.floor((endDate.getTime() - connectedDate.getTime()) / 1000);
  const hours = Math.floor(differenceInSeconds / 3600);
  const minutes = Math.floor((differenceInSeconds % 3600) / 60);
  const seconds = differenceInSeconds % 60;

  // Format difference conditionally
  const parts: string[] = [];
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}min`);
  if (seconds > 0) parts.push(`${seconds}s`);

  const formattedDifference = parts.join(" ");

  return { formattedConnectedTime, formattedDifference };
};


//2025-01-13T11:01:42.577599584Z to the desired format Jan 13 , 25, 4:28 PM
export function formatTimestamp(timestamp:Date) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Get the day, month, year, and time
  const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'short', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true
  };

  // Format the date to 'Jan 13, 25, 4:28 PM' with a space between time and AM/PM
  let formattedDate = date.toLocaleString('en-US', options);
  
  // Add space between time and AM/PM
  formattedDate = formattedDate.replace(',', '').replace(/(\d{1,2}:\d{2})(AM|PM)/, '$1 $2');

  return formattedDate;
}


export function fixJson(input:any) {
  // Add quotes around keys (user, TS)
  let fixed = input?.replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":');
  // Add quotes around values that look like strings (user and timestamps)
  fixed = fixed?.replace(/:\s*([a-zA-Z0-9\-T:.Z]+)(?=\s*[},])/g, ': "$1"');
  return JSON.parse(fixed);
}