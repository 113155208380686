import {
  SESSION_SET,
  SESSION_ERROR,
  RESET_SESSION_ERROR,
  INITIATE_LOGIN,
  INITIATE_RELOGIN,
  SET_CURRENT_TAB,
  SET_VPN_STATUS,
  SET_CALL_AGENT_STATUS,
  SET_CHAT_AGENT_STATUS,
  SET_CASE_AGENT_STATUS,
  UPDATE_COOKIE,
  INITIATE_DISCONNECT,
  INITIAL_DETAILS_SET,
  INITIATE_LOGIN_SESSION,
  INITIATE_LOGOUT,
  INITIATE_NAVIGATOR,
  SET_CURRENT_SESSION_TAB,
  SET_SESSION_CONNECTED,
  SET_SESSION_DISCONNECT,
  SET_SESSION_RECONNECT,
  RESET_SESSION,
  //   CALL_AGENT_STATUS_OPEN,
  VOICE_UTILIZATION_AVAILABLE,
  WEBRTC_SESSION_WINDOW,
  SET_MIC_STATUS,
  LOGOUT,
} from "../constants/action-types";

/**
 *
 * @param {*} state
 */
export function setSessionState(state: any) {
  return {
    type: SESSION_SET,
    ...state,
  };
}
export function setSessionError(error: any) {
  return {
    type: SESSION_ERROR,
    error,
  };
}

export function resetSessionError() {
  return {
    type: RESET_SESSION_ERROR,
  };
}

export function initiateLogin(isLoginProcessing: any) {
  return {
    type: INITIATE_LOGIN,
    isLoginProcessing,
  };
}
export function initiateRelogin(isReloginProcessing: any) {
  return {
    type: INITIATE_RELOGIN,
    isReloginProcessing,
  };
}

export function setVpnStatus(vpnConnected: any) {
  return {
    type: SET_VPN_STATUS,
    vpnConnected,
  };
}
export function setCallAgentStatusOpen(callAgentStatusOpen: any) {
  return {
    type: SET_CALL_AGENT_STATUS,
    callAgentStatusOpen,
  };
}
export function setChatAgentStatusOpen(chatAgentStatusOpen: any) {
  return {
    type: SET_CHAT_AGENT_STATUS,
    chatAgentStatusOpen,
  };
}
export function setCaseAgentStatusOpen(caseAgentStatusOpen: any) {
  return {
    type: SET_CASE_AGENT_STATUS,
    caseAgentStatusOpen,
  };
}
export function updateCookie(cookie: any) {
  return {
    type: UPDATE_COOKIE,
    cookie,
  };
}
export function setServerInitiatedDisconnect(serverInitiatedDisconnect: any) {
  return {
    type: INITIATE_DISCONNECT,
    serverInitiatedDisconnect,
  };
}
export function setInitialDetails(userType: any) {
  return {
    type: INITIAL_DETAILS_SET,
    userType,
  };
}
export function login(payload: any) {
  return {
    type: INITIATE_LOGIN_SESSION,
    payload,
  };
}
export function initiateLogout(payload: any) {
  return {
    type: INITIATE_LOGOUT,
    payload,
  };
}
export function initiateNavigator(payload: any) {
  return {
    type: INITIATE_NAVIGATOR,
    payload,
  };
}
export function setCurrentSessionTab(payload: any) {
  return {
    type: SET_CURRENT_SESSION_TAB,
    payload,
  };
}
export function setSessionConnected(payload: any) {
  return {
    type: SET_SESSION_CONNECTED,
    payload,
  };
}
export function setSessionDisconnected(payload: any) {
  return {
    type: SET_SESSION_DISCONNECT,
    payload,
  };
}
export function setSessionReconnect(payload: any) {
  return {
    type: SET_SESSION_RECONNECT,
    payload,
  };
}
export function resetSessionState() {
  return {
    type: RESET_SESSION,
  };
}
export function setVoiceUtilizationAvailable(payload: any) {
  return {
    type: VOICE_UTILIZATION_AVAILABLE,
    payload,
  };
}
export function setWebrtcWindowStatus(payload: any) {
  return {
    type: WEBRTC_SESSION_WINDOW,
    payload,
  };
}

export function setMicrophoneStatus(payload: { isMicEnabled: boolean }) {
  return {
    type: SET_MIC_STATUS,
    payload,
  };
}
