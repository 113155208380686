import React, { useEffect, useState } from "react";
import { Flex, ProgressCircle, Text, View } from "@adobe/react-spectrum";
import "./style.css";
import {
  callMonitorRequest,
  supervisorCallDetails,
} from "../../api/session-api";
import CallMonitor from "./CallMonitor";
import storeRTK from "../../store/storeRTK";
import { setSelectedTab } from "../../actions/common";
import { AgentStatusDetail } from "../../types/agent.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ActiveCallContainer from "../CallTab/CallDialer/ActiveCallContainer";
import { setMonitoringCall } from "../../actions/call";
import { showErrorToast } from "../../utils/utils";
import { fixJson, formatTimestamp } from "../../utils";

interface SupervisorCallDetailsProps {
  agentList: any;
  selectedAgent: string;
}

const SupervisorCallDetails: React.FC<SupervisorCallDetailsProps> = ({
  agentList,
  selectedAgent,
}) => {
  const [agentDetails, setAgentDetails] = useState<AgentStatusDetail | null>(
    null
  );
  const [monitorType, setMonitorType] = useState("silent");
  const [isDisabled, setIsDisabled] = useState(true);
  const [callDetails, setCallDetails] = useState<{
    attributes: Record<string, any>;
  } | null>(null);

  const [activeContactId, setActiveContactId] = useState("");

  const agent = useSelector((state: RootState) => state.agent);
  let agentName = agent.id.split("@")[0];
  const call = useSelector((state: RootState) => state.call);
  let agentBusinessAttributes = agent?.businessAttributes || [];
  let callCustomerAttributes = callDetails ? callDetails.attributes : {};
  let notesPresent = false;
  let notesPropertySpelling = "";
  let vpnStatus = useSelector(
    (state: RootState) => state.webrtc?.isVoiceNetworkAvailable
  );
  let micStatus = useSelector((state: RootState) => state.session.isMicEnabled);

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: any
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      notesPresent = true;
      notesPropertySpelling = entry.attributeName;
      return false;
    }
    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  // Group the matching attributes into sets of 3 (three columns per row)
  let finalArray = [];

  // Ensure we only take the first 9 matching attributes, if there are more (3 columns * 3 rows = 9 attributes max)
  const attributesToDisplay = matchingAttributes.slice(0, 9);

  for (let i = 0; i < attributesToDisplay.length; i += 3) {
    finalArray.push(
      <View key={`attribute-row-${i}`} marginTop={23} width={"100%"}>
        <Flex direction="row" justifyContent="space-between" gap={10}>
          {/* First column */}
          <Flex direction="column" minWidth={"30%"}>
            <Text UNSAFE_className="call-detail-title">
              {displayInProperFormat(attributesToDisplay[i]?.attributeName)}
            </Text>
            <Text UNSAFE_className="call-detail-description">
              {callCustomerAttributes[attributesToDisplay[i]?.attributeName]}
            </Text>
          </Flex>

          {/* Second column (if exists) */}
          {attributesToDisplay[i + 1]?.attributeName && (
            <Flex direction="column" minWidth={"30%"}>
              <Text UNSAFE_className="call-detail-title">
                {displayInProperFormat(
                  attributesToDisplay[i + 1]?.attributeName
                )}
              </Text>
              <Text UNSAFE_className="call-detail-description">
                {
                  callCustomerAttributes[
                    attributesToDisplay[i + 1]?.attributeName
                  ]
                }
              </Text>
            </Flex>
          )}

          {/* Third column (if exists) */}
          {attributesToDisplay[i + 2]?.attributeName && (
            <Flex direction="column">
              <Text UNSAFE_className="call-detail-title">
                {displayInProperFormat(
                  attributesToDisplay[i + 2]?.attributeName
                )}
              </Text>
              {displayInProperFormat(
                attributesToDisplay[i + 2]?.attributeName
              ) === "Aic Web Contact Monitored History" ? (
                <Text UNSAFE_className="call-detail-description">
                  {fixJson(
                    callCustomerAttributes[
                      attributesToDisplay[i + 2]?.attributeName
                    ]
                  ).map((detail: any) => {
                    return (
                      <Flex justifyContent={"space-between"} marginTop={4}>
                        <Text>{detail.user}</Text>
                        <Text>{formatTimestamp(detail.TS)}</Text>
                      </Flex>
                    );
                  })}
                </Text>
              ) : (
                <Text UNSAFE_className="call-detail-title">
                  {
                    callCustomerAttributes[
                      attributesToDisplay[i + 2]?.attributeName
                    ]
                  }
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      </View>
    );
  }

  // If notes are present, add them as a separate row.
  if (notesPresent) {
    finalArray.push(
      <Flex direction="column" marginTop={23} key="notes-row">
        <Text UNSAFE_className="call-detail-title">Transfer Notes</Text>
        <Text UNSAFE_className="call-detail-description">
          {callCustomerAttributes[notesPropertySpelling]}
        </Text>
      </Flex>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */

  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (agentList && agentList.length > 0) {
      setAgentDetails(agentList[0]);
    }
  }, [agentList]);

  useEffect(() => {
    const agent = agentList
      ? agentList.find(
          (agent: AgentStatusDetail) => agent.agent_ldap === selectedAgent
        )
      : {};
    setAgentDetails(agent);
    agent?.agent_current_contacts?.length > 0 &&
    !call.isMonitoringCall &&
    vpnStatus &&
    agentName !== selectedAgent &&
    !call.id &&
    agentDetails?.agent_current_contacts[0]?.contact_type !== "MONITOR"
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [
    selectedAgent,
    agentList,
    call.isMonitoringCall,
    vpnStatus,
    agentDetails,
  ]);

  useEffect(() => {
    const fetchCallDetails = async (contactId: string) => {
      try {
        const response = await supervisorCallDetails({
          contactId: contactId,
        });
        if (response?.status === "success") {
          setCallDetails(response?.callData);
        }
      } catch (error) {
        console.error("Failed to fetch call details", error);
      }
    };
    const newActiveContactId =
      (agentDetails?.agent_current_contacts?.length &&
        agentDetails?.agent_current_contacts[0]?.contact_id) ||
      "";
    if (newActiveContactId && newActiveContactId !== activeContactId) {
      setActiveContactId(newActiveContactId);
      fetchCallDetails(newActiveContactId);
    }
  }, [agentDetails, activeContactId]);

  const handleCallMonitoring = async () => {
    if (!micStatus) {
      showErrorToast(
        "Please enable microphone to monitor a call",
        "micErrorMonitorToast"
      );
      return;
    }
    let response = null;
    const contactId =
      (agentDetails?.agent_current_contacts?.length &&
        agentDetails?.agent_current_contacts[0]?.contact_id) ||
      null;
    const payload = {
      contactId: contactId,
      monitorType: monitorType,
    };
    if (contactId) {
      response = await callMonitorRequest(payload);
      if (response?.status === "success") {
        storeRTK.dispatch(setSelectedTab("1"));
        storeRTK.dispatch(setMonitoringCall(true));
      } else {
        showErrorToast(
          "Call monitor request failed. Please try again",
          "monitorError"
        );
      }
    }
  };

  return (
    <>
      <View backgroundColor={"gray-100"} width={"64%"} height={"100%"}>
        <Flex direction={"column"} height={"100%"}>
          <Flex
            UNSAFE_className="call-monitor-detail"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text UNSAFE_className="call-detail-heading">Call details</Text>
          </Flex>

          <Flex direction={"row"} height={"100%"}>
            <View width={"64%"} backgroundColor={"gray-100"} paddingX={32}>
              <Flex direction="row" justifyContent="space-between" height={"100%"}>
                {agentDetails?.agent_current_contacts?.length &&
                agentDetails?.agent_current_contacts[0]?.contact_id ? (
                  <>
                    {finalArray.length > 0 ? (
                      <View marginTop={5} width={"100%"}>
                        {finalArray}
                      </View>
                    ) : (
                      <Flex
                        height={"100%"}
                        alignItems={"center"}
                        direction={"column"}
                        justifyContent={"center"}
                        width={"64%"}
                      >
                        {agentDetails?.agent_current_contacts?.length &&
                        agentDetails?.agent_current_contacts[0]?.contact_id ? (
                          <Text
                            UNSAFE_className="call-detail-title"
                            marginTop={10}
                          >
                            No details found
                          </Text>
                        ) : (
                          <ProgressCircle
                            aria-label="Loading…"
                            isIndeterminate
                          />
                        )}
                      </Flex>
                    )}
                  </>
                ) : (
                  <ActiveCallContainer
                    title="No active call"
                    description=" There is no active call with the agent"
                  />
                )}
              </Flex>
            </View>
            <View
              paddingX={32}
              borderStartColor={"gray-200"}
              borderStartWidth={"thin"}
              height={"100%"}
              width={"36%"}
              backgroundColor={"gray-100"}
            >
              <CallMonitor
                monitorType={monitorType}
                setMonitorType={setMonitorType}
                handleCallMonitoring={handleCallMonitoring}
                isDisabled={isDisabled}
              />
            </View>
          </Flex>
        </Flex>
      </View>
    </>
  );
};

export default SupervisorCallDetails;
