import { checkMediaPermission } from "../chrome/tabs";
import { BG_MEDIA_PERM_CHECK } from "../constants/constants";
import Emitter from "../emitter";

export const registerPermissionCheckEvents = () => {
    // Media permission check events
    console.log("registerPermissionCheckEvents");
    checkMediaPermission((res: boolean) => {
        Emitter.emit(BG_MEDIA_PERM_CHECK,
        {
            type: "res",
            chan: BG_MEDIA_PERM_CHECK,
            body: { wasAllowed: res },
            success: true,
        },
        );
    });
}

