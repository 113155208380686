import { useState, useEffect } from "react";
import {
  Provider,
  defaultTheme,
  Dialog,
  Content,
  Heading,
  Divider,
  Text,
  ButtonGroup,
  Button,
  DialogContainer,
  AlertDialog,
  View,
  Link,
  Flex,
} from "@adobe/react-spectrum";
import Dashboard from "../Dashboard";
import Loader from "../Loader/Loader";
import Login from "./Login";
import { connect, useSelector } from "react-redux";
import "./index.css";
import {
  BG_MEDIA_PERM_CHECK,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  HIDE_MEDIA_PERM_POPUP,
  SESSION_LOGOUT_MESSAGE,
  SESSION_LOGOUT_MESSAGE_CLOSE,
  CCP_ASK_MEDIA_PERMISSION,
} from "../../constants/constants";
import { Alert } from "../common/Icons";
import {
  connectSession,
  registerConnectSessionHandlers,
} from "../../amazon-connect/handlers/session";
import Emitter from "../../emitter";
import storeRTK from "../../store/storeRTK";
import { setLoader } from "../../actions/common";
import registerEventHandlers from "../../handlers";
import { RootState } from "../../store/storeConfig";
import registerApiEvents from "../../api";
import registerEventHandlerControlllers from "../../controller";
import registerConnectApiHandlers from "../../amazon-connect/api";
import SessionTimer from "../SessionTimer";
import { checkMediaPermission } from "../../chrome/tabs";

function mapStateToProps(state: any) {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
  };
}

registerEventHandlers();
registerApiEvents();
registerEventHandlerControlllers();
// Listen to connect api events
registerConnectApiHandlers();
registerConnectSessionHandlers();
const LoginPage = (props: any) => {
  const [showPermPopup, setShowPermPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState<any>({});
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [showCCPPermPopup, setShowCCPPermPopup] = useState(false);
  const ccpSessionState = useSelector(
    (state: RootState) => state.webrtc.ccpSessionState
  );
  const isLoading = useSelector((state: RootState) => state.common.isLoading);

  useEffect(() => {
    //location.href = "/";
    const localStorageStateData = localStorage.getItem("reduxState");
    if (localStorageStateData !== null) {
      const savedStateData = JSON.parse(localStorageStateData);
      const { agent } = savedStateData || {};
      if (agent && agent.id) {
        connectSession.initiateCcpSession();
      }
      localStorage.removeItem("reduxState");
    }
    if (props.session?.isLoginProcessing) {
      storeRTK.dispatch(setLoader(true));
    } else {
      storeRTK.dispatch(setLoader(false));
    }

    // Check if media permission is allowed
    checkMediaPermission((res: boolean) => {
      if (!res) {
        setShowPermPopup(true);
      }
    });

    const handleCCPAskMediaPermission = (res: any) => {
      setShowCCPPermPopup(true);
    };

    const handleShowNotification = (body: any) => {
      setShowNotification(true);
      setNotification(body);
    };

    const handleHideNotification = () => {
      setShowNotification(false);
      setNotification({});
    };

    const handleHideMediaPermPopup = () => {
      setShowPermPopup(false);
      setShowCCPPermPopup(false);
    };

    const handleSessionLogoutMessage = () => {
      setShowLogoutDialog(true);
    };

    const handleSessionLogoutMessageClose = () => {
      setShowLogoutDialog(false);
    };

    // Subscribe to events
    // Emitter.on(BG_MEDIA_PERM_CHECK, handleMediaPermCheck);
    Emitter.on(CCP_ASK_MEDIA_PERMISSION, handleCCPAskMediaPermission);
    Emitter.on(SHOW_NOTIFICATION, handleShowNotification);
    Emitter.on(HIDE_NOTIFICATION, handleHideNotification);
    Emitter.on(HIDE_MEDIA_PERM_POPUP, handleHideMediaPermPopup);
    Emitter.on(SESSION_LOGOUT_MESSAGE, handleSessionLogoutMessage);
    Emitter.on(SESSION_LOGOUT_MESSAGE_CLOSE, handleSessionLogoutMessageClose);

    return () => {
      // Unsubscribe from events
      // Emitter.off(BG_MEDIA_PERM_CHECK, handleMediaPermCheck);
      Emitter.off(CCP_ASK_MEDIA_PERMISSION, handleCCPAskMediaPermission);
      Emitter.off(SHOW_NOTIFICATION, handleShowNotification);
      Emitter.off(HIDE_NOTIFICATION, handleHideNotification);
      Emitter.off(HIDE_MEDIA_PERM_POPUP, handleHideMediaPermPopup);
      Emitter.off(SESSION_LOGOUT_MESSAGE, handleSessionLogoutMessage);
      Emitter.off(
        SESSION_LOGOUT_MESSAGE_CLOSE,
        handleSessionLogoutMessageClose
      );
      // Clean up state
      setShowLogoutDialog(false);
    };
  }, [props.session.loggedIn, props.session.isLoginProcessing]);

  const openSettingsPage = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
      setShowPermPopup(false);
    });
    checkMediaPermission((res: boolean) => {
      console.log("openSettingsPage microphone permission res",res);
      if (res) {
        setShowPermPopup(false);
      }
    });
  };

  const openCCPPermissionTab = () => {
    // Check if media permission is allowed
    checkMediaPermission((res: boolean) => {
      console.log("openCCPPermissionTab Check microphone permission res",res);
      if (res) {
        setShowCCPPermPopup(false);
      }
    });
  };

  const handleOnPress = () => {
    setShowNotification(false);
  };

  const handleOnPressLogoutPopup = () => {
    setShowLogoutDialog(false);
  };

  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <View height={"100vh"} width={"100vw"}>
        <View height={"100%"}>
          {/* <ErrorBoundary> */}
          <View height={"100%"}>
            {!props.session.loggedIn && <Login />}
            {props.session.loggedIn && <Dashboard />}
            {
              <View>
                {isLoading ? (
                  <Loader checkLoading="loading" />
                ) : ccpSessionState !== 4 && ccpSessionState !== 3 ? (
                  <Loader checkLoading="webrtc" />
                ) : null}
              </View>
            }
          </View>
          <DialogContainer onDismiss={() => {}}>
            {showPermPopup && (
              <Dialog width={"500px"}>
                <Heading level={4}>
                  <Flex justifyContent={"space-between"}>
                    <span>Microphone permission required!</span>
                    <span
                      style={{
                        marginRight: "15px",
                      }}
                    >
                      <Alert />
                    </span>
                  </Flex>
                </Heading>
                <Divider />
                <Content>
                  <Text>
                    This app requires microphone access for call functionality. Please enable microphone permission and click "Continue"
                  </Text>
                  <br />
                  <br />
                  <span>If you are unable to find microphone setting, you can use </span><a href="https://wiki.corp.adobe.com/display/CXTS/AICv3+Production+User+Guide" target="_blank">this guide to enable mic access</a>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="cta"
                    onPress={() => {
                      openSettingsPage();
                    }}
                    autoFocus
                  >
                    Continue
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogContainer>
          <DialogContainer onDismiss={() => {}}>
            {showCCPPermPopup && (
              <Dialog>
                <Heading level={4}>
                  <span>
                    <span
                      style={{
                        marginRight: "15px",
                      }}
                    >
                      <Alert />
                    </span>
                    <span>Permission Required</span>
                  </span>
                </Heading>
                <Divider />
                <Content>
                  <Text>
                    This app will not work unless microphone permission is
                    allowed. Click below button to continue{" "}
                  </Text>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="cta"
                    onPress={() => {
                      openCCPPermissionTab();
                    }}
                    autoFocus
                  >
                    Allow
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogContainer>
          <DialogContainer onDismiss={() => {}}>
            {showLogoutDialog && (
              <Dialog>
                <Heading level={4}>
                  <span>
                    <span
                      style={{
                        marginRight: "15px",
                      }}
                    >
                      <Alert />
                    </span>
                    <span>Logged out</span>
                  </span>
                </Heading>
                <Divider />
                <Content>
                  <Text>
                    You were logged out due to network disconnect. Please login
                    again to continue
                  </Text>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="cta"
                    onPress={handleOnPressLogoutPopup}
                    autoFocus
                  >
                    ok
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogContainer>
          {/* </ErrorBoundary> */}
          <DialogContainer onDismiss={() => handleOnPress()}>
            {showNotification && (
              <AlertDialog
                title={notification.title || "Network error!"}
                variant={notification.type || "information"}
                primaryActionLabel="Ok"
                onPrimaryAction={handleOnPress}
              >
                {notification.message}
              </AlertDialog>
            )}
          </DialogContainer>
        </View>
      </View>
      {props.session.loggedIn && <SessionTimer />}
    </Provider>
  );
};

export default connect(mapStateToProps)(LoginPage);
