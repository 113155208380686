import { Avatar, Flex, Heading, View, Text } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import ContactIcon from "../../../assets/contactIcon.svg";
import CallIcon from "../../../assets/callIcon.svg";
import StarIcon from "../../../assets/starIcon.svg";
import Emitter from "../../../emitter";
import { API_CALL_DIAL } from "../../../constants/constants";
import storeRTK from "../../../store/storeRTK";
import { setSelectedTab } from "../../../actions/common";
import { RootState } from "../../../store/storeConfig";
import { showErrorToast } from "../../../utils/utils";
import { fixJson, formatTimestamp } from "../../../utils";
import { Call } from "../../../types/call.type";
import { BusinessAttributes } from "../../../types/agent.type";

interface ICallHistoryDetailProps {
  activeCallDetail: Call;
}

const CallHistoryDetail = (props: ICallHistoryDetailProps) => {
  const { activeCallDetail } = props;
  let vpnStatus = useSelector(
    (state: RootState) => state.webrtc?.isVoiceNetworkAvailable
  );
  const { call } = useSelector((state: RootState) => state);
  const micStatus = useSelector(
    (state: RootState) => state.session.isMicEnabled
  );

  const dialCall = (phoneNumber: string) => {
    if (!micStatus) {
      showErrorToast("Please enable microphone to make a call", "micError");
      return;
    }
    Emitter.emit(API_CALL_DIAL, { phoneNumber });
    storeRTK.dispatch(setSelectedTab("1"));
  };

  const { agent } = useSelector((state: RootState) => state);

  let callCustomerAttributes = activeCallDetail?.customer?.attributes || {};
  let agentBusinessAttributes = agent?.businessAttributes || [];
  let notesPresent = false;
  let notesPropertySpelling = "";

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: BusinessAttributes
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      notesPresent = true;
      notesPropertySpelling = entry.attributeName;
      return false;
    }

    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  // Group the matching attributes into sets of 3 (three columns per row)
  let finalArray = [];

  // Ensure we only take the first 9 matching attributes, if there are more (3 columns * 3 rows = 9 attributes max)
  const attributesToDisplay = matchingAttributes.slice(0, 9);

  for (let i = 0; i < attributesToDisplay.length; i += 3) {
    finalArray.push(
      <>
        <View key={`attribute-row-${i}`} marginTop={23} width={"100%"}>
          <Flex direction="row" justifyContent="space-between" wrap="wrap">
            {/* First column */}
            <Flex direction="column" width={"34%"}>
              <Text UNSAFE_className="call-detail-title">
                {displayInProperFormat(attributesToDisplay[i]?.attributeName)}
              </Text>
              <Text UNSAFE_className="call-detail-description">
                {callCustomerAttributes[attributesToDisplay[i]?.attributeName]}
              </Text>
            </Flex>

            {/* Second column (if exists) */}
            {attributesToDisplay[i + 1]?.attributeName && (
              <Flex direction="column" width={"33%"}>
                <Text UNSAFE_className="call-detail-title">
                  {displayInProperFormat(
                    attributesToDisplay[i + 1]?.attributeName
                  )}
                </Text>
                <Text UNSAFE_className="call-detail-description">
                  {
                    callCustomerAttributes[
                      attributesToDisplay[i + 1]?.attributeName
                    ]
                  }
                </Text>
              </Flex>
            )}

            {/* Third column (if exists) */}
            {attributesToDisplay[i + 2]?.attributeName && (
              <Flex direction="column" width={"33%"}>
                <Text UNSAFE_className="call-detail-title">
                  {displayInProperFormat(
                    attributesToDisplay[i + 2]?.attributeName
                  )}
                </Text>
                {displayInProperFormat(
                  attributesToDisplay[i + 2]?.attributeName
                ) === "Aic Web Contact Monitored History" ? (
                  <Text UNSAFE_className="call-detail-description">
                    {fixJson(
                      callCustomerAttributes[
                        attributesToDisplay[i + 2]?.attributeName
                      ]
                    ).map((detail: any, index: number) => {
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          marginTop={4}
                          key={index}
                        >
                          <Text>{detail.user}</Text>
                          <Text>{formatTimestamp(detail.TS)}</Text>
                        </Flex>
                      );
                    })}
                  </Text>
                ) : (
                  <Text UNSAFE_className="call-detail-title">
                    {
                      callCustomerAttributes[
                        attributesToDisplay[i + 2]?.attributeName
                      ]
                    }
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        </View>
      </>
    );
  }

  // If notes are present, add them as a separate row.
  if (notesPresent) {
    finalArray.push(
      <Flex direction="column" marginTop={23} key="notes-row">
        <Text UNSAFE_className="call-detail-title">Transfer Notes</Text>
        <Text UNSAFE_className="call-detail-description">
          {callCustomerAttributes[notesPropertySpelling]}
        </Text>
      </Flex>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */

  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  return (
    <>
      <View
        borderColor="gray-200"
        borderWidth="thin"
        height={"100%"}
        backgroundColor={"gray-100"}
        UNSAFE_className="call-history-detail"
      >
        <View
          padding="10px 15px"
          borderBottomWidth="thin"
          borderBottomColor="gray-200"
        >
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex>
              <Avatar
                src="https://i.imgur.com/kJOwAdv.png"
                alt="avatar with custom size"
                size={30}
              />
              <View marginX={12}>
                <Heading level={3} margin={0}>
                  <Text UNSAFE_className="call-history-detail-title">
                    {activeCallDetail.customer?.attributes?.Username}
                  </Text>
                </Heading>
                <Text UNSAFE_className="call-history-detail-subtitle">
                  {activeCallDetail.customer?.attributes?.Customer_ANI ||
                  activeCallDetail.customer?.phoneNumber
                    ? activeCallDetail.customer?.phoneNumber
                    : "-"}
                </Text>
              </View>
            </Flex>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <button className="call-history-action-btn" disabled>
                <img src={StarIcon} alt="favourite icon" />
                <Text marginX={7} UNSAFE_style={{ whiteSpace: "nowrap" }}>
                  Add to favourites
                </Text>
              </button>
              <button className="call-history-action-btn" disabled>
                <img src={ContactIcon} alt="contact icon" />
                <Text marginX={7} UNSAFE_style={{ whiteSpace: "nowrap" }}>
                  Add to speed dials
                </Text>
              </button>
              <button
                onClick={() =>
                  dialCall(activeCallDetail.customer?.displayName ?? "")
                }
                disabled={call?.id || !vpnStatus}
                className={`call-history-action-btn call-history-action-btn-call ${
                  call?.id || !vpnStatus ? "call-btn-disabled" : ""
                }`}
              >
                <img src={CallIcon} alt="call icon" />
                <Text marginX={7}>Call</Text>
              </button>
            </Flex>
          </Flex>
        </View>
        <View padding={"0px 16px"}>
          <View marginTop={23}>{finalArray}</View>
        </View>
      </View>
    </>
  );
};

export default CallHistoryDetail;
