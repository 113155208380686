import {
  CALL_RESET,
  CALL_SET,
  CALL_SET_CONSULT,
  LOGOUT,
  CALL_SET_CONSULT_TYPE,
  UPDATE_CALL,
  UPDATE_CALL_TRANSFER_ENDPOINTS,
  RESET_NORMAL_CALL,
  STORE_LOGIN,
  RESET_STATE,
  UPDATE_MONITORING_CALL_STATUS,
  HISTORY_FILTER_CALL_COUNT,
} from "../constants/action-types";
import { Call } from "../types/call.type";

const initialState: Call = {
  id: "",
  customer: null,
  self: null,
  queue: undefined,
  remaining: undefined,
  allowedTransferEndpoints: [],
  isMonitoringCall: false,
  callCount:0,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CALL_SET:
      if (action.call?.id) {
        return {
          ...state,
          ...action.call,
        };
      }
      return {
        ...initialState,
      };

    case CALL_SET_CONSULT:
      return {
        ...state,
        consultCall: action.consultCall,
      };
    case CALL_SET_CONSULT_TYPE:
      return {
        ...state,
        consultType: action.consultType,
      };
    case LOGOUT:
    case CALL_RESET:
      return {
        ...initialState,
      };
    case UPDATE_CALL:
      const connectedTime = new Date(
        action.call.self?.connectedTime || ""
      ).getTime();

      const endTime = action.call.self?.endTime
        ? new Date(action.call.self?.endTime).getTime()
        : 0;
      return {
        ...state,
        ...action.call,
        self: {
          ...action.call.self,
          connectedTime,
          endTime,
        },
      };
    case UPDATE_CALL_TRANSFER_ENDPOINTS:
      return {
        ...state,
        allowedTransferEndpoints: action.call,
      };
    case RESET_NORMAL_CALL:
      return {
        ...initialState,
      };
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload?.call,
      };
    case RESET_STATE:
      return {};
    case UPDATE_MONITORING_CALL_STATUS:
      return {
        ...state,
        isMonitoringCall: action.isMonitoringCall,
      };
    case HISTORY_FILTER_CALL_COUNT:
      return {
        ...state,
        callCount: action.callCount,
      };
    default:
      return state;
  }
};
