import storeRTK from "../store/storeRTK";
import {
  setSessionError,
  setSessionState,
  resetSessionError,
  initiateLogin,
  initiateRelogin,
  setVpnStatus,
  setCurrentSessionTab,
  initiateLogout,
  initiateNavigator,
  setMicrophoneStatus,
} from "../actions/session";
import {
  START_SESSION,
  STOP_SESSION,
  SESSION_STATE,
  HIDE_LOADER,
  SESSION_ERROR,
  RESET_SESSION_ERROR,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
  BG_NOTIFICATION,
  BG_INITIATE_LOGIN,
  BG_INITIATE_RELOGIN,
  API_TAB_CHANGE,
  INTERNAL_VPN_STATUS_UPDATE,
  SHOW_MESSAGE,
  BG_MESSAGE,
  BG_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_API_STATUS_MESSAGE,
  BG_API_STATUS,
  BG_TELEMETRY,
  ALLOWED_CONV_MEDIA_TYPES,
  SHOW_CCP_CONNECTION_ERROR,
  CCP_STATE,
  API_CALL_REJECT,
  API_CALL_HANGUP,
} from "../constants/constants";
import { setCallState, setMonitoringCall } from "../actions/call";
import { setCompleteAgentState } from "../actions/agent";
import { setLogoutState } from "../actions/status";
import { setHistoryState } from "../actions/history";
import { setCallbackState } from "../actions/callback";
import {
  setCCPState,
  setWebRTC,
  updateVoiceNewtworkState,
} from "../actions/webrtc";
import { setInitialChatState } from "../actions/chat";
import { setInitialCaseState } from "../actions/case";
import {
  fetchWithRetry,
  renameKeys,
  customFetch,
  checkInternet,
  showErrorToast,
} from "../utils/utils";
import { loginSession, logoutSession } from "../connector/session";
import { setCompleteAgentData } from "../controller/agent";
import {
  AGENT_STATUS_LIST,
  COOKIE_AWS,
  NETWORK_DISCONNECT_RECONNECT,
  SESSION_TIMER_WARNING,
} from "../constants/common";
import {
  BG_INFO,
  CCP_PONG,
  INIT_CCP,
  SESSION_TIMER_ALERT,
  VOICE_NETWORK_STATE,
} from "../constants/events";
import { setLoader } from "../actions/common";
import Emitter from "../emitter";
import {
  changeAgentState,
  checkVoicePolicy,
  getAgentGeoLocation,
  getlostMessages,
} from "../api/session-api";
import { Agent } from "../types/agent.type";
import { sendMessage } from "../services";
import { IndividualChat } from "../types/chat.type";
import { getAicServerUrl } from "../config";
import { logoutChannel } from "../App";
import { STATE_LOGIN } from "../amazon-connect/constants";
import { SessionEvent } from "../types/call.type";

/**
 * Setting the Agent States
 * @param {*} messageEnvelope
 */
function setSessionStates(messageEnvelope: any) {
  storeRTK.dispatch(setCompleteAgentState(messageEnvelope.body.agent));
  storeRTK.dispatch(setCallState(messageEnvelope.body.call));
  storeRTK.dispatch(setSessionState(messageEnvelope.body.session));
  storeRTK.dispatch(setHistoryState(messageEnvelope.body.history));
  storeRTK.dispatch(setCallbackState(messageEnvelope.body.callback));
  storeRTK.dispatch(setWebRTC(messageEnvelope.body.webrtc));
  storeRTK.dispatch(setInitialChatState(messageEnvelope.body.chat));
  storeRTK.dispatch(setInitialCaseState(messageEnvelope.body.cases));

  storeRTK.dispatch(setLoader(false));
  Emitter.emit(HIDE_NOTIFICATION);
}

function handleLogout() {
  storeRTK.dispatch(setLogoutState());
  Emitter.emit(HIDE_LOADER);
}

function handleSessionError(ev: any) {
  if (ev.body) {
    storeRTK.dispatch(setSessionError(ev.body));
  }
}
function handleResetSessionError() {
  storeRTK.dispatch(resetSessionError());
}

function handleBgNotification(body: any) {
  // if(body?.body) {
  //     Emitter.emit(SHOW_NOTIFICATION, body.body);
  // }
}

function handleInitiateLogin(body: any) {
  storeRTK.dispatch(initiateLogin(body.body));
}
function handleInitiateRelogin(body: any) {
  storeRTK.dispatch(initiateRelogin(body.body));
}

function handleTabChange(ev: any) {
  if (ev.body) {
    storeRTK.dispatch(setCurrentSessionTab(ev.body.id));
  }
}
function handleVpnChange(ev: any) {
  if (ev.body) {
    storeRTK.dispatch(setVpnStatus(ev.body.vpnConnected));
  }
}

function handleBgErrorMessage(message: any) {
  if (message.body) {
    Emitter.emit(SHOW_ERROR_MESSAGE, message.body);
  }
}

function handleApiStatus(message: any) {
  if (message.body) {
    Emitter.emit(SHOW_API_STATUS_MESSAGE, message.body);
  }
}

/**
 *
 * @param agentConfiguration //agent profile from ccp
 * @returns combined agent profile
 */
export async function getProfile(): Promise<Agent | undefined> {
  try {
    const CURRENT_DOMAIN = getAicServerUrl();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/profile`,
      json: "",
      method: "GET",
      isJsonResponse: false,
      credentials: "include",
    };
    const response: any = await fetchWithRetry(customFetch, params);
    const profile = await response;
    const allowedMedia = Object.keys(
      profile?.agentProfile?.allowed_conversation_media[0]
    )
      .filter(
        (key) =>
          profile?.agentProfile?.allowed_conversation_media[0][key] === "true"
      )
      .map((value) => value.toUpperCase());
    const businessAttributes = profile.agentProfile.business_attributes
      ? renameKeys(
          profile.agentProfile.business_attributes,
          "attribute_name",
          "attributeName"
        )
      : [];
    const voice_transfer_queues = profile.agentProfile.business_attributes
      ? renameKeys(
          profile?.agentProfile?.voice_transfer_queues,
          "queue_name",
          "displayName"
        )
      : [];
    const updateIdVoiceTransfer = voice_transfer_queues
      ? renameKeys(voice_transfer_queues, "queue_id", "id")
      : [];
    const transferQueues = {
      allowedTransferQueues: updateIdVoiceTransfer ? updateIdVoiceTransfer : [],
    };
    const updateQueueAssignedName = profile.agentProfile.queues_assigned
      ? renameKeys(profile.agentProfile.queues_assigned, "queue_name", "name")
      : [];
    const updateQueueAssignedId = updateQueueAssignedName
      ? renameKeys(updateQueueAssignedName, "queue_id", "id")
      : [];
    const modifiedQueueAssigned = {
      entities: updateQueueAssignedId
        ? updateQueueAssignedId.map((val: any) => {
            // Check if 'joined' key exists before applying JSON.parse
            const joinedValue = val.joined
              ? JSON.parse(val.joined as any)
              : val.joined;

            return { ...val, joined: joinedValue };
          })
        : [],
    };

    const chattransferQueues = profile.agentProfile.chat_transfer_queues
      ? renameKeys(
          profile?.agentProfile?.chat_transfer_queues,
          "queue_name",
          "displayName"
        )
      : [];
    const modifiedchattransferQueues = {
      allowedTransferQueues: chattransferQueues
        ? chattransferQueues.map((stateObj: any, index: any) => ({
            id: stateObj.displayName,
            ...stateObj,
          }))
        : [],
    };
    const agentStates = profile.agentProfile.agent_states
      ? renameKeys(profile?.agentProfile?.agent_states, "state", "displayName")
      : [];
    const modifiedagentStates = {
      allowedStates: agentStates
        ? agentStates.map((stateObj: any, index: any) => ({
            id: stateObj.displayName,
            ...stateObj,
          }))
        : [],
    };
    const allowedStatesIndexes = modifiedagentStates
      ? modifiedagentStates?.allowedStates.map(
          (stateObject: any, i: number) => {
            const key = stateObject?.id; // Access the id property safely
            return {
              [key]: i,
            };
          }
        )
      : [];

    const allowedStatesIndex = allowedStatesIndexes
      ? allowedStatesIndexes.reduce((result: any, currentObject: any) => {
          const [key, value] = Object.entries(currentObject)[0];
          result[key] = value;
          return result;
        }, {})
      : [];
    const states = {
      allowedStates: modifiedagentStates
        ? modifiedagentStates?.allowedStates
        : [],
      allowedStatesIndex: allowedStatesIndex ? allowedStatesIndex : [],
    };
    const agentState = states ? states?.allowedStates[0] : {};
    const stateModifiedDate = new Date().getTime();

    let queueSelection = null;
    if (profile?.agentProfile?.queue_selection?.length > 0) {
      queueSelection = profile?.agentProfile?.queue_selection[0]?.allowed
        ? JSON.parse(profile?.agentProfile?.queue_selection[0]?.allowed)
        : profile?.agentProfile?.queue_selection[0]?.allowed;
    }

    return Promise.resolve({
      id: profile?.agentProfile?.agent_info?.email || "",
      firstName: profile?.agentProfile?.agent_info?.firstName || "Unknown",
      lastName: profile?.agentProfile?.agent_info?.lastName || "Unknow",
      email: profile?.agentProfile?.agent_info?.email || "",
      state: agentState,
      agentInfo: profile?.agentProfile?.agent_info,
      aicConfig: profile?.agentProfile?.aic_config,
      instanceConfig: profile?.agentProfile?.instance_config,
      stateModifiedDate: stateModifiedDate,
      acwState: null,
      acwStateModifiedDate: 0,
      groupId: "abc13920-47d0-478b-879b-69d8f3bc830d",
      states: states,
      businessAttributes: businessAttributes,
      cannedResponses: [],
      chatTransferQueues: modifiedchattransferQueues,
      allowedConversationMedia: allowedMedia,
      availableMedia: ["VOICE"],
      sessionPingInterval: profile?.agentProfile?.aic_config?.ping_interval,
      vpnCheckUrl: profile?.agentProfile?.aic_config?.vpn_check_url,
      transferQueues: transferQueues,
      queuesAssigned: modifiedQueueAssigned,
      queueSelectionAllowed: queueSelection,
      routingProfileConCurrency:
        profile?.agentProfile?.routing_profile_concurrency,
      crm: profile?.agentProfile?.crm,
      agentLoginTime: new Date().toISOString(),
    });
  } catch (error: any) {
    // store.dispatch(loginFailure("Error in getting profile api"));
    return Promise.reject(error);
  }
}

export const CCP_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  CONNECTED: 4,
  DISCONNECTED: 5,
};
let retryCount = 0;
const maxRetries = 2;
const delay = 30000; // 30 seconds

class Session {
  ccpStateInterval: any = null;
  geoLocationInterval: any = null;
  agentProfile: any = null;
  sessionTimer: any = null;
  lastPingTime: number = 0;
  constructor() {
    this.onloggedIn = this.onloggedIn.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.startCcpConnection = this.startCcpConnection.bind(this);
    this.sendSessionTimeoutAlert = this.sendSessionTimeoutAlert.bind(this);
    this.checkSessionTimeout = this.checkSessionTimeout.bind(this);
    this.getGeoLocation = this.getGeoLocation.bind(this);
    this.clearCallOnNetworkVPNDisconnect =
      this.clearCallOnNetworkVPNDisconnect.bind(this);
    this.performNetworkReconnectActions =
      this.performNetworkReconnectActions.bind(this);
    this.checkCCPStateAndRetry = this.checkCCPStateAndRetry.bind(this);
  }

  getGeoLocation() {
    try {
      getAgentGeoLocation();
    } catch (error: any) {
      Emitter.emit(BG_TELEMETRY, {
        type: "notif",
        chan: BG_INFO,
        body: {
          type: SESSION_ERROR,
          chan: SESSION_ERROR,
          message: "Error in getting geo location Api",
          timestamp: new Date().getTime(),
        },
      });
      console.log("Error in getting geo location", error);
    }
  }

  login() {
    setTimeout(() => {
      getProfile()
        .then((agentProfile: any) => {
          this.agentProfile = agentProfile;
          Emitter.emit(INIT_CCP, agentProfile);
          if (agentProfile && agentProfile.email) {
            this.agentProfile = agentProfile;
            // Checking CCP Access when it is true then only to proceed to login.
            const ccpAccess =
              typeof agentProfile?.aicConfig?.CCP_ACCESS === "string"
                ? JSON.parse(agentProfile?.aicConfig?.CCP_ACCESS)
                : agentProfile?.aicConfig?.CCP_ACCESS;
            if (ccpAccess) {
              this.startCcpConnection({
                ccp_url: agentProfile?.aicConfig?.ccp_url || "",
              });
            } else {
              // Emitter.emit(BG_TELEMETRY, {
              //     type: 'notif',
              //     chan: BG_INFO,
              //     body: {
              //         type:BG_LOGIN_DETAILS,
              //         chan:BG_LOGIN_DETAILS,
              //         info: `Login failed due to ccp access false for user ${agentProfile?.email}`
              //     },
              // });
              // storeRTK.dispatch(
              //   loginFailure(
              //     "We're sorry, but you don't currently have permission to proceed."
              //   )
              // );
            }
          } else {
            // storeRTK.dispatch(
            //   loginFailure("Error in getting profile, no email id received")
            // );
          }
        })
        .catch((error: any) => {
          console.error("Error in getting profile api", error);
          storeRTK.dispatch(logoutSession("Error in calling profile api"));
          //storeRTK.dispatch(loginFailure("Error in getting profile"));
        });
    }, 500);
  }

  logout() {
    console.log("Logging out: Logout function called!!!");
    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      body: {
        type: STOP_SESSION,
        chan: STOP_SESSION,
        info: `Logging out: Logout function called!!!`,
      },
    });
    if (this.ccpStateInterval) {
      clearInterval(this.ccpStateInterval);
      this.ccpStateInterval = undefined;
    }
    // clear the session timer
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
      this.sessionTimer = undefined;
    }
    // clear the geo location timer
    if (this.geoLocationInterval) {
      clearInterval(this.geoLocationInterval);
      this.geoLocationInterval = undefined;
    }
    const state = storeRTK.getState();
    if (state.session.loggedIn) {
      const state = storeRTK.getState();
      if (!state.session.isLogoutProcessing) {
        storeRTK.dispatch(initiateLogout({ isLogoutProcessing: true }));
        storeRTK.dispatch(logoutSession("normal logout, logging out user"));
        storeRTK.dispatch(setCurrentSessionTab({ id: "none" }));
        logoutChannel.postMessage("Logout");
      } else {
        console.log(
          "Logout already in progress, not sending logout request again"
        );
        initiateLogout({ isLogoutProcessing: false });
        storeRTK.dispatch(logoutSession("normal logout, logging out user"));
        storeRTK.dispatch(setCurrentSessionTab({ id: "none" }));
        logoutChannel.postMessage("Logout");
      }
    } else {
      storeRTK.dispatch(logoutSession("No session conn, logging out user"));
      storeRTK.dispatch(setCurrentSessionTab({ id: "none" }));
      logoutChannel.postMessage("Logout");
    }

    try {
      stopSession();
    } catch (e) {
      console.log("stop session api failed and returned error", e);
    }
    storeRTK.dispatch(logoutSession("No session conn, logging out user"));
    // window.location.reload();
  }

  onloggedIn(ev: any) {
    if (ev.success) {
      const state = storeRTK.getState();
      const isLoggedIn = state.session.loggedIn;
      setCompleteAgentData(isLoggedIn ? state.agent : this.agentProfile);
      const initialState = {
        type: "notif",
        body: {
          agent: isLoggedIn ? state.agent : this.agentProfile,
          state: { loggedIn: true },
          call: {},
          case: {},
        },
        success: true,
      } as any;
      storeRTK.dispatch(loginSession(initialState.body));
      // if (this.agentProfile?.email && initialState && state.session.loggedIn !== true) {
      //     storeRTK.dispatch(loginSession(initialState.body));
      //     sendMessageToOffscreen(ev);
      //     // Meanwhile Fetch cannedResponse too
      //     getCannedResponses();
      // } else if(!this.agentProfile?.email){
      //   storeRTK.dispatch(loginFailure("Error in getting profile, no email id received"));
      // }
      //  else {
      //     console.log("Already logged in not updating");
      // }

      if (initialState?.body?.agent && state.session.loggedIn !== true) {
        // Emitter.emit(BG_TELEMETRY, {
        //     type: 'notif',
        //     chan: BG_INFO,
        //     body: {
        //         type: BG_MESSAGE,
        //         chan: BG_MESSAGE,
        //         message: `User with email ${initialState?.body?.agent?.email} logged into AICv3 v${process.env.VERSION} at ${new Date().getTime()}`,
        //     },
        // });
      } else if (state.session.loggedIn === true) {
        let geoLocatInterval;
        if (state.agent?.aicConfig?.agent_geo_location_time_interval) {
          geoLocatInterval =
            state.agent?.aicConfig?.agent_geo_location_time_interval * 1000;
        } else if (
          this.agentProfile?.aicConfig?.agent_geo_location_time_interval
        ) {
          geoLocatInterval =
            this.agentProfile?.aicConfig?.agent_geo_location_time_interval *
            1000;
        } else {
          // If agent_geo_location_time is NaN, set geoLocationTime to default value
          geoLocatInterval = 5000;
        }
        clearInterval(this.geoLocationInterval);
        this.geoLocationInterval = setInterval(() => {
          this.getGeoLocation();
        }, geoLocatInterval);

        clearInterval(this.ccpStateInterval);
        this.ccpStateInterval = setInterval(() => {
          this.startCcpConnection();
        }, 5000);

        clearInterval(this.sessionTimer);
        this.sessionTimer = setInterval(() => {
          this.checkSessionTimeout();
        }, 5000);

        // Emitter.emit(BG_TELEMETRY, {
        //     type: 'notif',
        //     chan: BG_INFO,
        //     body: {
        //         type: BG_MESSAGE,
        //         chan: BG_MESSAGE,
        //         message: `User with email ${initialState?.body?.agent?.email} closed and reopen ccp-client window or service worker restarted of AICv3 v${process.env.VERSION} at ${new Date().getTime()}`,
        //     },
        // });
      }
      //This is to check on login if agent has right group assigned or not
      // if (initialState?.body?.agent) {
      //     const agent = initialState?.body?.agent;
      //     if (!agent?.allowedConversationMedia || agent?.allowedConversationMedia?.length === 0) {
      //         setTimeout(() => sendMessage(
      //             {
      //                 type: 'notif',
      //                 chan: BG_NOTIFICATION,
      //                 body: {
      //                     type: 'error',
      //                     title: MISSING_GROUP_TITLE,
      //                     message: MISSING_GROUP_MSG,
      //                 },
      //             }
      //         ), 500);
      //     };
      // }
    } else {
      const errorEvent = ev as SessionEvent<string>;
      console.log("Error during login", errorEvent);
      storeRTK.dispatch(initiateLogin(false));
      // storeRTK.dispatch(
      //     loginFailure(errorEvent.body || 'Error occurred during login.')
      // );
    }
  }

  resetCCPsessionState() {
    storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
    sendMessage({
      type: "notif",
      chan: CCP_STATE,
      body: {
        status: CCP_STATES.CLOSED,
      },
    });
    Emitter.emit(BG_TELEMETRY, {
      type: "notif",
      chan: BG_INFO,
      body: {
        type: "notif",
        chan: CCP_STATE,
        body: {
          status: CCP_STATES.CLOSED,
          message: `ccp state is reset.`,
        },
      },
    });
  }

  // Call getState onLoggedIn
  isVoiceAllowed() {
    const state = storeRTK.getState();
    if (
      // check if voice available in allowedConversationMedia
      state.agent?.allowedConversationMedia?.filter(
        (media: any) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
      )?.length !== 0
    ) {
      if (state.agent?.availableMedia?.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async checkEligiblityForVoice() {
    console.log("Checking eligibility for voice");
    if (!this.isVoiceAllowed()) {
      return false;
    }
    return await checkVoicePolicy().then((res) => res.voice_allowed);
  }

  checkCCPStateAndRetry() {
    const state = storeRTK.getState();
    if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
      if (retryCount < maxRetries) {
        retryCount++;
        this.performNetworkReconnectActions(true);
        setTimeout(() => {
          if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
            if (retryCount < maxRetries) {
              this.checkCCPStateAndRetry();
            } else {
              retryCount = 0;
              console.log("Max retries reached. Could not connect to CCP.");
              sendMessage({
                type: "notif",
                chan: SHOW_CCP_CONNECTION_ERROR,
                body: {
                  message: "Max retries reached. Could not connect to CCP.",
                  timestamp: new Date().getTime(),
                },
              });

              Emitter.emit(BG_TELEMETRY, {
                type: "notif",
                chan: BG_INFO,
                success: true,
                body: {
                  type: SHOW_CCP_CONNECTION_ERROR,
                  chan: SHOW_CCP_CONNECTION_ERROR,
                  message: "Max retries reached. Could not connect to CCP.",
                  timestamp: new Date().getTime(),
                  context: { agent: true },
                },
              });
            }
          }
        }, delay * retryCount);
      } else {
        retryCount = 0;
        console.log("Max retries reached. Could not connect to CCP.");
        // sendMessage({
        //     type: 'notif',
        //     chan: SHOW_CCP_CONNECTION_ERROR,
        //     body: {
        //         message: 'Max retries reached. Could not connect to CCP.',
        //         timestamp: new Date().getTime(),
        //     },
        // });

        // Emitter.emit(BG_TELEMETRY, {
        //     type: 'notif',
        //     chan: BG_INFO,
        //     success: true,
        //     body: {
        //         type: SHOW_CCP_CONNECTION_ERROR,
        //         chan: SHOW_CCP_CONNECTION_ERROR,
        //         message: 'Max retries reached. Could not connect to CCP.',
        //         timestamp: new Date().getTime(),
        //         context: {agent: true}
        //     }
        // });
      }
    }
  }
  performNetworkReconnectActions = (retry?: boolean) => {
    //reset session error state also

    const state = storeRTK.getState();
    // if(state.webrtc.ccpSessionState === CCP_STATES.CONNECTED) {
    //     sendMessage({
    //         type: 'notif',
    //         chan: CCP_STATE,
    //         body: {
    //             status: CCP_STATES.CONNECTED
    //         }
    //     })
    //     return;
    // }
    const currentTime = new Date().getTime();
    const timeSinceLastPing = currentTime - this.lastPingTime;
    const retryPrefix = retry ? "RETRYING: " : "";
    const prefix =
      retryPrefix +
      `NETWORK_RECONNECTED: User ${state.agent.firstName} ${
        state.agent.lastName
      } with email = ${state.agent?.email} is online now at ${new Date()}`;
    console.log(
      `${prefix}, his current state is = ${state.agent.state}, perform connection refresh actions at}`
    );

    if (state.session.error) {
      storeRTK.dispatch(resetSessionError());
    }
    // storeRTK.dispatch(initiateNavigator({ isOnline: true }));

    if (state?.chat?.chats?.length > 0) {
      try {
        state?.chat?.chats?.forEach((ch: IndividualChat) => {
          if (ch?.id) {
            getlostMessages(ch.id);
          }
        });
      } catch (error) {
        console.log("error get lost messages", error);
      }
    }

    // close ccp window if exists so that it reopens again and makes fresh connection
    const logoutTimeout = state.agent?.aicConfig?.network_disconnect_logout_time
      ? state.agent?.aicConfig?.network_disconnect_logout_time * 1000
      : 10000; // 10 seconds in milliseconds
    if (timeSinceLastPing > logoutTimeout || retry) {
      // const wid = ccpWindow.getCcpWindowId();
      // TODO: write restart ccp connection logic here
    } else {
      console.log(
        `${prefix}, not refreshing connection due to short disconnect duration}`
      );
    }
  };
  async startCcpConnection(t?: { ccp_url: string }) {
    let micStatus = false;
    const isInternetConnected = await checkInternet();
    const state = storeRTK.getState();

    if (isInternetConnected) {
      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => (micStatus = true))
        .catch(() => (micStatus = false));
      storeRTK.dispatch(setMicrophoneStatus({ isMicEnabled: micStatus }));

      if (!state.session.isOnline && state.session.loggedIn) {
        //if user is offline and network is back, perform reconnect actions
        const sessionTimedOut = this.isSessionTimeout();
        if (!sessionTimedOut) {
          this.performNetworkReconnectActions();
          setTimeout(() => {
            this.checkCCPStateAndRetry();
          }, 30000);
        }
      }
      this.lastPingTime = new Date().getTime();
      let isEligibleForVoice = await this.checkEligiblityForVoice();
      if (!isEligibleForVoice) {
        //save in store and call tab red
        storeRTK.dispatch(updateVoiceNewtworkState(false));
        // Emitter.emit(VOICE_NETWORK_STATE, {
        //   body: { isVoiceNetworkAvailable: false },
        // });
        // Clear call if call exists
        this.clearCallOnNetworkVPNDisconnect();
      } else {
        storeRTK.dispatch(updateVoiceNewtworkState(true));
      }
    } else {
      if (state.webrtc.ccpSessionState !== CCP_STATES.CLOSED) {
        storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
      }
      storeRTK.dispatch(
        setSessionError({
          message: NETWORK_DISCONNECT_RECONNECT,
          type: "ping",
          timestamp: new Date().getTime(),
        })
      );
      storeRTK.dispatch(initiateNavigator({ isOnline: false }));
      // if (state?.callback?.selfState === "RINGING") {
      //   rejectCallback();
      // }
    }
  }

  checkSessionTimeout() {
    const state = storeRTK.getState();
    const { aicConfig } = state.agent;
    const sessionWarningTime =
      (Number(aicConfig?.session_warning_duration) || 0) * 1000 ||
      SESSION_TIMER_WARNING * 1000; //converting to mili seconds
    if (state?.session?.loggedInAt) {
      const sessionDuration = new Date().getTime() - state?.session?.loggedInAt;
      if (sessionDuration > sessionWarningTime) {
        this.sendSessionTimeoutAlert();
      }
    }
  }

  sendSessionTimeoutAlert() {
    const state = storeRTK.getState();
    if (state?.agent?.state?.displayName != AGENT_STATUS_LIST.AVAILABLE) {
      const stateOfAgent = state?.agent?.states?.allowedStates.filter(function (
        st: any
      ) {
        return st.displayName === AGENT_STATUS_LIST.AVAILABLE;
      });
      if (typeof stateOfAgent !== "undefined" && stateOfAgent.length > 0) {
        changeAgentState({ agentState: stateOfAgent[0].id });
      }
    }

    if (!state?.call?.id && !state?.chat?.activeChatID) {
      Emitter.emit(SESSION_TIMER_ALERT, {
        type: "notif",
        body: { dialog: true },
      });
    }
  }

  clearCallOnNetworkVPNDisconnect() {
    const state = storeRTK.getState();
    if (state.call.id) {
      if (
        state.call.self?.state === "ESTABLISHED" ||
        state.call.self?.state === "DIALING"
      ) {
        Emitter.emit(API_CALL_HANGUP, {
          body: { conversationId: state.call.id },
        });
        showErrorToast(
          "Call disconnected due to network/VPN disconnect",
          "callHangupToast"
        );
        storeRTK.dispatch(setMonitoringCall(false));
      } else {
        Emitter.emit(API_CALL_REJECT, {
          body: {
            conversationId: state.call?.id,
            disconnectReason: "Network Disconnect",
          },
        });
      }
    }
  }

  isSessionTimeout() {
    const state = storeRTK.getState();
    const { aicConfig } = state.agent;
    const sessionWarningTime =
      (Number(aicConfig?.session_warning_duration) || 0) * 1000 ||
      SESSION_TIMER_WARNING * 1000; //converting to mili seconds
    if (state?.session?.loggedInAt) {
      const sessionDuration = new Date().getTime() - state?.session?.loggedInAt;
      if (sessionDuration > sessionWarningTime) {
        return true;
      }
    }
    return false;
  }
}

export const session = new Session();

function deleteCookie(cookieNames: Array<String>) {
  cookieNames.forEach((cookieItem: String) => {
    document.cookie = `${cookieItem}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
}

export async function stopSession() {
  try {
    const CURRENT_DOMAIN = getAicServerUrl();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/aws/web/stopSession`,
      json: "",
      method: "GET",
      isJsonResponse: true,
    };
    let response: any = await customFetch(params);
    deleteCookie(COOKIE_AWS);
    return response.json();
  } catch (error) {
    console.log("Error in stop session during logout", error);
    return false;
  }
}

/**
 * Register Default Events for Session
 */
export default function registerSessionEvents() {
  Emitter.on(START_SESSION, session.login);
  // Emitter.on(SESSION_STATE, (m) => {
  //   setSessionStates(m);
  // });
  Emitter.on(BG_NOTIFICATION, handleBgNotification);
  Emitter.on(BG_ERROR_MESSAGE, handleBgErrorMessage);
  Emitter.on(STOP_SESSION, handleLogout);
  Emitter.on(SESSION_ERROR, handleSessionError);
  Emitter.on(RESET_SESSION_ERROR, handleResetSessionError);
  Emitter.on(BG_INITIATE_LOGIN, handleInitiateLogin);
  Emitter.on(BG_INITIATE_RELOGIN, handleInitiateRelogin);
  Emitter.on(API_TAB_CHANGE, handleTabChange);
  Emitter.on(INTERNAL_VPN_STATUS_UPDATE, handleVpnChange);
  Emitter.on(BG_API_STATUS, handleApiStatus);
  Emitter.on(STATE_LOGIN, session.onloggedIn);
}
