import { useEffect, useState } from "react";
import { Button, Flex, Grid, Text } from "@adobe/react-spectrum";
import PhoneInput from "react-phone-number-input";
import { BackspaceIcon, CallIcon, CrossIcon } from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_DIAL,
  CALL_CANNOT_DIAL,
  FETCH_ABORT_TIMEOUT,
  SHOW_NOTIFICATION,
} from "../../../constants/constants";
import { createPromiseForFetch } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import { showErrorToast } from "../../../utils/utils";

const CallDialPad = () => {
  const { call } = useSelector((state: RootState) => state);
  const [number, setNumber] = useState("");
  const [dialInProgress, setDialInProgress] = useState(false);
  const [dialError, setDialError] = useState("");
  // const [isdefaultCountry, setDefaultCountry] =
  //   useState<string>("United States");

  let agent = useSelector((state: RootState) => state.agent);
  let micStatus = useSelector((state: RootState) => state.session.isMicEnabled);
  let vpnStatus = useSelector(
    (state: RootState) => state.webrtc?.isVoiceNetworkAvailable
  );

  useEffect(() => {
    if (call?.id) {
      setNumber(call?.customer.phoneNumber);
    }
  }, [call?.id]);

  // useEffect(() => {
  //   checkVoicePolicy()
  //     .then((res) => setDefaultCountry(res.country))
  //     .catch((err) => console.log("Error while getting default country", err));
  // }, []);

  const renderDialer = (num: number) => {
    const displayNumMap: { [key: number]: any } = {
      10: "*",
      11: "0",
      12: "#",
      13: <BackspaceIcon />,
    };

    const displayNum = displayNumMap[num] ?? num;

    const handleBtnpress = (num: number) => {
      setNumber((prevNumber) => {
        if (num === 13) {
          return prevNumber.slice(0, -1);
        } else if (num === 14) {
          return "";
        } else {
          return prevNumber + displayNum;
        }
      });
    };

    return (
      <button
        key={num}
        onClick={() => handleBtnpress(num)}
        className="dialer-btn"
      >
        {displayNum}
      </button>
    );
  };

  const validateNumber = (number: string) => {
    const regex = new RegExp(/^(?=.{1,30}$)([+]*[0-9a-zA-Z]+[#()+ -]*)/);
    return regex.test(number);
  };

  const makeCall = () => {
    setDialInProgress(false);
    if (!micStatus) {
      showErrorToast("Please enable microphone to receive a call", "micError");
      return;
    }
    if (!dialInProgress && micStatus) {
      const temp = number
        .replace(/ /g, "")
        .replace(/-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "");

      if (validateNumber(number)) {
        setDialInProgress(true);
        if (navigator.onLine) {
          try {
            Promise.any([
              createPromiseForFetch(
                agent.vpnCheckUrl?.split(",")[0],
                FETCH_ABORT_TIMEOUT
              ),
              createPromiseForFetch(
                agent.vpnCheckUrl?.split(",")[1],
                FETCH_ABORT_TIMEOUT
              ),
            ])
              .then(() => {
                Emitter.emit(API_CALL_DIAL, {
                  phoneNumber: temp.charAt(0) === "+" ? temp : "+" + temp, //Fix for +1 appended by CCP
                });
              })
              .catch(() => {
                setDialInProgress(false);
                Emitter.emit(SHOW_NOTIFICATION, {
                  message: CALL_CANNOT_DIAL,
                  title: "Error",
                  type: "warning",
                });
              });
          } catch (error) {
            setDialInProgress(false);
            Emitter.emit(SHOW_NOTIFICATION, {
              message: CALL_CANNOT_DIAL,
              title: "Error",
              type: "warning",
            });
          }
        } else {
          setDialInProgress(false);
          Emitter.emit(SHOW_NOTIFICATION, {
            message: CALL_CANNOT_DIAL,
            title: "Error",
            type: "warning",
          });
        }
      } else {
        setDialError("Invalid number");
      }
    }
  };

  return (
    <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
      <Flex justifyContent={"center"} direction={"column"}>
        <Flex direction={"row"} width={"100%"}>
          <PhoneInput
            value={number}
            onChange={(value) => setNumber(value ?? "")}
            international
            className={"call-input"}
            defaultCountry={"US"}
          />
          <span onClick={() => setNumber("")} className="cross-btn">
            <CrossIcon />
          </span>
        </Flex>
        <Flex justifyContent={"center"} marginTop={20} width={"100%"}>
          <Grid
            areas={["1 2 3", "4 5 6", "7 8 9", "* 0 #", "call"]}
            columns={["1fr", "1fr", "1fr"]}
            rows={["1fr", "1fr", "1fr", "1fr", "auto"]}
            alignItems="center"
            justifyItems="center"
            height="100%"
            width={"100%"}
            gap={0}
            rowGap={7}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((num) =>
              renderDialer(num)
            )}
            <Flex gridColumn="span 1">{renderDialer(13)}</Flex>
            <Flex marginStart={'10px'} width={"100%"} gridColumn="span 2">
              <Button
                onPress={makeCall}
                aria-label="call-dial-button"
                // UNSAFE_className="call-dial-button"
                // UNSAFE_style={{ width: "calc(100%-7px)", borderRadius: "24px", cursor: "pointer" }}
                isDisabled={!vpnStatus}
                variant={"accent"}
                width={"calc(100% - 12px)"}
                height={'100%'}
                >
                  <CallIcon fill="#FFFFFF" />
                  <span style={{marginLeft:'10px'}}>Call</span>
              </Button>
            </Flex>
          </Grid>
        </Flex>
        {/* <Flex direction={"row"} marginTop={"10px"} flexBasis={2}>
          <Flex width={"100%"}>
            <button
              onClick={makeCall}
              aria-label="call-dial-button"
              className="call-dial-button"
              style={{ borderRadius: "24px", cursor: "pointer", flex: "2" }}
              disabled={!vpnStatus}
            >
              <Flex alignItems={"center"} justifyContent={"center"}>
                {call?.id ? (
                  <Text>Consult</Text>
                ) : (
                  <>
                    <CallIcon fill="#FFFFFF" />
                    <Text>&nbsp; Call</Text>
                  </>
                )}
              </Flex>
            </button>
          </Flex>
          <Flex marginStart={"10px"}>{renderDialer(13)}</Flex>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default CallDialPad;
