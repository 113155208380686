import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./components/Login";
import LoginCallback from "./components/Login/LoginCallback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "./store";
import { useEffect, useState } from "react";
import storeRTK from "./store/storeRTK";
import { logoutSession } from "./connector/session";
import { CURRENT_DOMAIN } from "./api/session-api";
import { worker } from "./main";
import TabCountSync from "./utils/TabCountSync";
import Emitter from "./emitter";
import { START_SESSION } from "./constants/constants";

export const logoutChannel = new BroadcastChannel("logout");

function mapStateToProps(state: RootState) {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
    geoData: state.agent?.agentInfo?.geoLocation,
    agent: state.agent,
  };
}

const App = (props: any) => {
  const [tabs, setTabs] = useState(1);

  useEffect(() => {
    if (props.session.isLoginProcessing === true && props.loggedIn === false) {
      Emitter.emit(START_SESSION);
    }
  }, []);

  useEffect(() => {
    logoutAllTabs();
  }, []);

  useEffect(() => {
    const tcs = new TabCountSync();
    window.addEventListener("unload", () => tcs.close());
    tcs.subscribe((count: number) => {
      setTabs(count);
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      //hide prompt on login page
      if (props.agent.id && props.agent.id !== "") {
        if (tabs < 2) {
          event.preventDefault();
          event.returnValue = "";

          worker.port.postMessage({
            url: `${CURRENT_DOMAIN}/v1/session/agent/web/removeCCPProfile`,
            geoData: props.geoData,
          });
        }
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props.agent]);

  const logout = () => {
    logoutChannel.postMessage("Logout");
    storeRTK.dispatch(logoutSession("No session conn, logging out user"));
  };

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      logout();
      logoutChannel.close();
    };
  };

  return (
    <Provider
      theme={defaultTheme}
      colorScheme="light"
      height={"100vh"}
      width={"100vw"}
    >
      <ToastContainer limit={1} />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login/redirect" element={<LoginCallback />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default connect(mapStateToProps)(App);
