import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { Provider } from "react-redux";
import storeRTK from "./store/storeRTK.ts";
import 'react-phone-number-input/style.css';

export const worker = new SharedWorker(
  new URL("./sharedWorker.js", import.meta.url),
  {
    type: "module",
    name: "Aic Web Worker",
  },
);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <Provider store={storeRTK}>
      <App />
    </Provider>
);
