import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider, Flex, View } from "@adobe/react-spectrum";
import "./style.css";
import CaseAi from "../../CasePage/CaseAI/CaseAi";
import RecentCalls from "./RecentCalls";
import CallDialPad from "./CallDialpad";
import ActiveCallContainer from "./ActiveCallContainer";
import { RootState } from "../../../store/storeConfig";
import { setHistoryfilterCallCount } from "../../../actions/call";
import storeRTK from "../../../store/storeRTK";

const CallDialer = () => {
  const { history } = useSelector((state: RootState) => state);
  const { conversations } = history || {};

  useEffect(() => {
    storeRTK.dispatch(setHistoryfilterCallCount(conversations.length));
  }, []);

  return (
    <Flex justifyContent={"space-around"} width={"100%"} height={"100%"}>
      <View width={"100%"}>
        <Flex
          direction={"row"}
          height={"100%"}
          UNSAFE_className="call-container"
        >
          <Flex
            direction={"column"}
            height={"100%"}
            width={`${conversations.length ? "69%" : "100%"}`}
            UNSAFE_className="call-dialer-container"
          >
            <Flex UNSAFE_className="call-active-container">
              <ActiveCallContainer
                title="No active call"
                description=" Start a new call or wait for the call"
              />
            </Flex>
            <Flex UNSAFE_className="call-dialpad-container">
              <CallDialPad />
            </Flex>
          </Flex>
          {conversations.length > 0 && (
            <Flex width={"27.1%"} UNSAFE_style={{ backgroundColor: "#F8F8F8" }}>
              <RecentCalls />
            </Flex>
          )}
        </Flex>
      </View>
    </Flex>
  );
};

export default CallDialer;
